import { Module } from 'vuex';
import { cloneDeep } from 'lodash';
import { RootState } from '@/store/types/rootState';
import { View, SettingsQuery, ViewOrderParams } from '@/store/types/modules';
import { FacilityUrlQuery } from '@/models/facility';

interface ModernDocument extends Document {
  // known issue: xxFullscreenEnabled properties are not in the Document interface yet
  msFullscreenEnabled?: boolean;
  mozFullscreenEnabled?: boolean;
  webkitFullscreenEnabled?: boolean;
  msFullscreenElement?: Element | null;
  mozFullscreenElement?: Element | null;
  webkitFullscreenElement?: Element | null;
}

const fullscreenEnabled = (): boolean => {
  const doc: ModernDocument = document;
  return !!(
    doc.fullscreenEnabled ||
    doc.msFullscreenEnabled ||
    doc.mozFullscreenEnabled ||
    doc.webkitFullscreenEnabled
  );
};

export const hasFullscreenElem = (): boolean => {
  const doc: ModernDocument = document;
  return !!(
    doc.fullscreenElement ||
    doc.msFullscreenElement ||
    doc.mozFullscreenElement ||
    doc.webkitFullscreenElement
  );
};

export const enterFullScreen = (): void => {
  const elem = (document as any).documentElement;
  if (fullscreenEnabled() && !hasFullscreenElem()) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    }
  }
};

const exitFullscreen = (): void => {
  const doc: any = document;
  if (fullscreenEnabled() && hasFullscreenElem()) {
    if (doc.exitFullscreen) {
      doc.exitFullscreen();
    } else if (doc.mozCancelFullScreen) {
      doc.mozCancelFullScreen();
    } else if (doc.webkitExitFullscreen) {
      doc.webkitExitFullscreen();
    } else if (doc.msExitFullscreen) {
      doc.msExitFullscreen();
    }
  }
};

const tenantModule: Module<View, RootState> = {
  namespaced: true,
  state: {
    settingsQuery: {},
    isPublicDisplayView: false,
    viewsOrder: [],
  },
  getters: {
    getViewOrderByBuildingAndFloor: state => (building: string, floor: string): string[] => {
      const viewBuilding = state.viewsOrder.find(v => v.building === building);
      const viewFloor = viewBuilding ? viewBuilding.floors.find(v => v.floor === floor) : undefined;
      return viewFloor ? viewFloor.order : [];
    },
  },
  mutations: {
    setSettingsQuery(state: View, settingsQuery: SettingsQuery): void {
      state.settingsQuery = cloneDeep(settingsQuery);
    },
    setBookingFacility(state: View, bookingFacility: Pick<FacilityUrlQuery, 'building'>): void {
      state.settingsQuery.bookingFacility = bookingFacility;
    },
    setPublicDisplayView(state: View, isPublicDisplayView: boolean): void {
      state.isPublicDisplayView = isPublicDisplayView;
    },

    setViewOrderByBuildingAndFloor(state: View, params: ViewOrderParams): void {
      const viewBuilding = state.viewsOrder.find(v => v.building === params.building);
      if (viewBuilding) {
        const viewFloor = viewBuilding.floors.find(v => v.floor === params.floor);
        if (viewFloor) {
          viewFloor.order = params.order;
        } else {
          viewBuilding.floors.push({
            floor: params.floor,
            order: params.order,
          });
        }
      } else {
        state.viewsOrder.push({
          building: params.building,
          floors: [
            {
              floor: params.floor,
              order: params.order,
            },
          ],
        });
      }
    },

    resetViewsOrder(state: View): void {
      state.viewsOrder = [];
    },
    setSettingsCurrent(state: View, building: string): void {
      if (state.settingsQuery.facilitiesSignage) {
        state.settingsQuery.facilitiesSignage.current = building;
      }
    },
    resetSettingsQuery(state: View): void {
      state.settingsQuery = {};
    },
  },
  actions: {
    setPublicDisplayView({ commit }, isPublicDisplayView: boolean): void {
      commit('setPublicDisplayView', isPublicDisplayView);
      if (isPublicDisplayView) {
        enterFullScreen();
      } else {
        exitFullscreen();
      }
    },
    reset({ commit }): void {
      commit('resetSettingsQuery');
      commit('resetViewsOrder');
    },
    enterFullScreen():void {
      enterFullScreen();
    }
  },
};

export default tenantModule;
