/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IMIntegrationSummary
 */
export interface IMIntegrationSummary {
    /**
     * 
     * @type {string}
     * @memberof IMIntegrationSummary
     */
    im_integration_id: string;
    /**
     * 
     * @type {string}
     * @memberof IMIntegrationSummary
     */
    im_tenant_id: string;
    /**
     * 
     * @type {string}
     * @memberof IMIntegrationSummary
     */
    im_provider_id: IMIntegrationSummaryImProviderIdEnum;
    /**
     * 
     * @type {Date}
     * @memberof IMIntegrationSummary
     */
    updated?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof IMIntegrationSummary
     */
    active: boolean;
}

export function IMIntegrationSummaryFromJSON(json: any): IMIntegrationSummary {
    return IMIntegrationSummaryFromJSONTyped(json, false);
}

export function IMIntegrationSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): IMIntegrationSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'im_integration_id': json['im_integration_id'],
        'im_tenant_id': json['im_tenant_id'],
        'im_provider_id': json['im_provider_id'],
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'active': json['active'],
    };
}

export function IMIntegrationSummaryToJSON(value?: IMIntegrationSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'im_integration_id': value.im_integration_id,
        'im_tenant_id': value.im_tenant_id,
        'im_provider_id': value.im_provider_id,
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'active': value.active,
    };
}

/**
* @export
* @enum {string}
*/
export enum IMIntegrationSummaryImProviderIdEnum {
    Email = 'email',
    Slack = 'slack',
    Teams = 'teams',
    Hangouts = 'hangouts'
}


