/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventBaseAttendee
 */
export interface EventBaseAttendee {
    /**
     * 
     * @type {string}
     * @memberof EventBaseAttendee
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof EventBaseAttendee
     */
    display_name?: string;
}

export function EventBaseAttendeeFromJSON(json: any): EventBaseAttendee {
    return EventBaseAttendeeFromJSONTyped(json, false);
}

export function EventBaseAttendeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventBaseAttendee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'display_name': !exists(json, 'display_name') ? undefined : json['display_name'],
    };
}

export function EventBaseAttendeeToJSON(value?: EventBaseAttendee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'display_name': value.display_name,
    };
}


