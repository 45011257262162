/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IMUserPreference
 */
export interface IMUserPreference {
    /**
     * 
     * @type {string}
     * @memberof IMUserPreference
     */
    im_user_id: string;
    /**
     * 
     * @type {Date}
     * @memberof IMUserPreference
     */
    updated?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof IMUserPreference
     */
    active: boolean;
}

export function IMUserPreferenceFromJSON(json: any): IMUserPreference {
    return IMUserPreferenceFromJSONTyped(json, false);
}

export function IMUserPreferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): IMUserPreference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'im_user_id': json['im_user_id'],
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'active': json['active'],
    };
}

export function IMUserPreferenceToJSON(value?: IMUserPreference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'im_user_id': value.im_user_id,
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'active': value.active,
    };
}


