/**
 * User component helper
 *
 * @desc helps do CRUD in components for user
 */
import { Store } from 'vuex';

import { UserDetails , AuthToken , Idp } from '@/store/types/modules';
import apiConfiguration from '@/helpers/config';
import { User } from '@/api/models';
import { UserApi, PostUsersMePreferenceRequest } from '@/api/apis/UserApi';
import { authMiddleware } from '@/helpers/authToken';

import { FlashMessage } from '@/store/flashMessage';

import { UserPreference } from '@/api/models/UserPreference';

interface UserHelperDeps {
  $gettext: (msgid: string) => string;
  $store: Store<{
    authToken: AuthToken;
    flashMessage: FlashMessage;
    user: UserDetails;
    idp: Idp;
  }>;
}

export default class UserHelper {
  vue: UserHelperDeps;

  constructor(deps: UserHelperDeps) {
    this.vue = deps;
  }

  /**
   * getUserDetails gets the detail of user information.
   *
   * @return {Promise<UserDetails>} Promise object involved that
   * the flag represented if auth API requests is succeeded
   */
  async getUserDetails(): Promise<UserDetails> {
    let me: User;

    try {
      const userApi = new UserApi(apiConfiguration)
        .withMiddleware(authMiddleware);
      me = await userApi.getUsersMe();
    } catch (e) {
      return Promise.reject(e);
    }

    if (!me) {
      return Promise.reject(new Error('Empty user'));
    }

    return {
      accountActive: me.active,
      accountId: me.id,
      accountEmails: me.emails,
      accountTimezone: me.timezone,
      accountLanguage: me.language,
      accountRoles: me.roles
    };
  }

  /**
   * getUserPreferences gets the general perferences of user.
   *
   * @return {Promise<UserPreference>} Promise object involved that
   * the flag represented if auth API requests is succeeded
   */
  async getUserPreferences(): Promise<UserPreference> {
    return new UserApi(apiConfiguration)
      .withMiddleware(authMiddleware)
      .getUsersMePreference();
  }

  /**
   * postUserPreferences updates the general perferences of user.
   *
   * @return {Promise<void>} Promise object involved that
   * the flag represented if auth API requests is succeeded
   */
  async postUserPreferences(newPreference: PostUsersMePreferenceRequest): Promise<void> {
    return new UserApi(apiConfiguration)
      .withMiddleware(authMiddleware)
      .postUsersMePreference(newPreference);
  }
}
