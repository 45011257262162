/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthenticateRequest
 */
export interface AuthenticateRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthenticateRequest
     */
    idp: AuthenticateRequestIdpEnum;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateRequest
     */
    client: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateRequest
     */
    token: string;
}

export function AuthenticateRequestFromJSON(json: any): AuthenticateRequest {
    return AuthenticateRequestFromJSONTyped(json, false);
}

export function AuthenticateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idp': json['idp'],
        'client': json['client'],
        'token': json['token'],
    };
}

export function AuthenticateRequestToJSON(value?: AuthenticateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idp': value.idp,
        'client': value.client,
        'token': value.token,
    };
}

/**
* @export
* @enum {string}
*/
export enum AuthenticateRequestIdpEnum {
    Google = 'google',
    Microsoft = 'microsoft'
}


