/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthenticateRequest,
    AuthenticateRequestFromJSON,
    AuthenticateRequestToJSON,
    Token,
    TokenFromJSON,
    TokenToJSON,
} from '../models';

export interface PostAuthTokenRequest {
    authenticateRequest: AuthenticateRequest;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Refresh token if it is possible
     * Refresh token
     */
    async getAuthTokenRaw(): Promise<runtime.ApiResponse<Token>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/auth/token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
     * Refresh token if it is possible
     * Refresh token
     */
    async getAuthToken(): Promise<Token> {
        const response = await this.getAuthTokenRaw();
        return await response.value();
    }

    /**
     * Authenticate to get a token
     * Authenticate with an OpenID Connect ID token
     */
    async postAuthTokenRaw(requestParameters: PostAuthTokenRequest): Promise<runtime.ApiResponse<Token>> {
        if (requestParameters.authenticateRequest === null || requestParameters.authenticateRequest === undefined) {
            throw new runtime.RequiredError('authenticateRequest','Required parameter requestParameters.authenticateRequest was null or undefined when calling postAuthToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticateRequestToJSON(requestParameters.authenticateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
     * Authenticate to get a token
     * Authenticate with an OpenID Connect ID token
     */
    async postAuthToken(requestParameters: PostAuthTokenRequest): Promise<Token> {
        const response = await this.postAuthTokenRaw(requestParameters);
        return await response.value();
    }

}
