/* eslint-disable import/prefer-default-export */

/**
 * Facility view model
 *
 * @desc represents view model of a facility on UI and functions to manages data of facility
 */
import {
  Facility,
  FacilityFromJSON,
  FacilityStatus,
  FacilityFeatures,
  FacilityStatusFromJSON,
} from '@/api/models';

export enum FacilityStatusNameEnum {
  Vacant = 'vacant',
  Occupied = 'occupied',
  NoAttendees = 'no_attendees',
  NoReservation = 'no_reservation',
}

export type FacilitySearchQuery = { [key in keyof FacilityFeatures]: string[] };

export type FacilityUrlQuery = {
  publicAccess?: boolean;
  sort?: string;
  current?: string;
} & { [key in keyof FacilityFeatures]: string[] };

/**
 * @name UiFacility
 *
 * @desc Represents the interface for facility for UI
 */
export interface UiFacility extends Facility, FacilityStatus {
  // eslint-disable-next-line camelcase
  status_name?: FacilityStatusNameEnum;
}

export function UiFacilityFromJson(json: any): UiFacility {
  const newF = FacilityFromJSON(json);
  const newFs = FacilityStatusFromJSON(json);
  return { ...newF, ...newFs, ...{ status_name: json.status_name } } as UiFacility;
}
