


















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';


@Component
export default class FacilityBookConfirmation extends Vue {
  @Prop({ type: Boolean, default: false, required: false }) greyscale!: boolean;

  @Prop({ type: Boolean, default: false, required: false }) large!: boolean;

}
