/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Facility,
    FacilityFromJSON,
    FacilityToJSON,
    FacilityEvent,
    FacilityEventFromJSON,
    FacilityEventToJSON,
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    InlineResponse2001,
    InlineResponse2001FromJSON,
    InlineResponse2001ToJSON,
} from '../models';

export interface GetTenantEventsRequest {
    tenantId: string;
    startTime: Date;
    endTime: Date;
    facilityId?: string;
    activeFacilityOnly?: boolean;
}

export interface GetTenantFacilitiesRequest {
    tenantId: string;
}

export interface GetTenantFacilitiesStatusRequest {
    tenantId: string;
}

export interface GetTenantFacilityRequest {
    tenantId: string;
    facilityId: string;
}

export interface GetTenantFacilityStatusRequest {
    tenantId: string;
    facilityId: string;
}

/**
 * 
 */
export class FacilityApi extends runtime.BaseAPI {

    /**
     * Get events of a tenant on a specific timerange
     * Get events of a tenant on a specific timerange
     */
    async getTenantEventsRaw(requestParameters: GetTenantEventsRequest): Promise<runtime.ApiResponse<Array<FacilityEvent>>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getTenantEvents.');
        }

        if (requestParameters.startTime === null || requestParameters.startTime === undefined) {
            throw new runtime.RequiredError('startTime','Required parameter requestParameters.startTime was null or undefined when calling getTenantEvents.');
        }

        if (requestParameters.endTime === null || requestParameters.endTime === undefined) {
            throw new runtime.RequiredError('endTime','Required parameter requestParameters.endTime was null or undefined when calling getTenantEvents.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.startTime !== undefined) {
            queryParameters['start_time'] = (requestParameters.startTime as any).toISOString();
        }

        if (requestParameters.endTime !== undefined) {
            queryParameters['end_time'] = (requestParameters.endTime as any).toISOString();
        }

        if (requestParameters.facilityId !== undefined) {
            queryParameters['facility_id'] = requestParameters.facilityId;
        }

        if (requestParameters.activeFacilityOnly !== undefined) {
            queryParameters['active_facility_only'] = requestParameters.activeFacilityOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenant_id}/events`.replace(`{${"tenant_id"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FacilityEventFromJSON));
    }

    /**
     * Get events of a tenant on a specific timerange
     * Get events of a tenant on a specific timerange
     */
    async getTenantEvents(requestParameters: GetTenantEventsRequest): Promise<Array<FacilityEvent>> {
        const response = await this.getTenantEventsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get facilities with devices and calendar set up
     * Get facilities of tenants
     */
    async getTenantFacilitiesRaw(requestParameters: GetTenantFacilitiesRequest): Promise<runtime.ApiResponse<Array<Facility>>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getTenantFacilities.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenant_id}/facilities`.replace(`{${"tenant_id"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(FacilityFromJSON));
    }

    /**
     * Get facilities with devices and calendar set up
     * Get facilities of tenants
     */
    async getTenantFacilities(requestParameters: GetTenantFacilitiesRequest): Promise<Array<Facility>> {
        const response = await this.getTenantFacilitiesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get both event and usage status of facilities
     * Get status of facilities of a tenant
     */
    async getTenantFacilitiesStatusRaw(requestParameters: GetTenantFacilitiesStatusRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getTenantFacilitiesStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenant_id}/facilities_status`.replace(`{${"tenant_id"}}`, encodeURIComponent(String(requestParameters.tenantId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     * Get both event and usage status of facilities
     * Get status of facilities of a tenant
     */
    async getTenantFacilitiesStatus(requestParameters: GetTenantFacilitiesStatusRequest): Promise<InlineResponse200> {
        const response = await this.getTenantFacilitiesStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a facility with devices and calendar set up
     * Get a facility of tenants
     */
    async getTenantFacilityRaw(requestParameters: GetTenantFacilityRequest): Promise<runtime.ApiResponse<Facility>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getTenantFacility.');
        }

        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling getTenantFacility.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenant_id}/facilities/{facility_id}`.replace(`{${"tenant_id"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"facility_id"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FacilityFromJSON(jsonValue));
    }

    /**
     * Get a facility with devices and calendar set up
     * Get a facility of tenants
     */
    async getTenantFacility(requestParameters: GetTenantFacilityRequest): Promise<Facility> {
        const response = await this.getTenantFacilityRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get both event and usage status of the specified facility
     * Get status of a facility of a tenant
     */
    async getTenantFacilityStatusRaw(requestParameters: GetTenantFacilityStatusRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getTenantFacilityStatus.');
        }

        if (requestParameters.facilityId === null || requestParameters.facilityId === undefined) {
            throw new runtime.RequiredError('facilityId','Required parameter requestParameters.facilityId was null or undefined when calling getTenantFacilityStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenant_id}/facilities_status/{facility_id}`.replace(`{${"tenant_id"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"facility_id"}}`, encodeURIComponent(String(requestParameters.facilityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Get both event and usage status of the specified facility
     * Get status of a facility of a tenant
     */
    async getTenantFacilityStatus(requestParameters: GetTenantFacilityStatusRequest): Promise<InlineResponse2001> {
        const response = await this.getTenantFacilityStatusRaw(requestParameters);
        return await response.value();
    }

}
