










import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'portable-fetch';
import 'url-polyfill';
import 'abortcontroller-polyfill';

import _ from 'lodash';
import * as Sentry from '@sentry/browser';
import Vue from 'vue';
import Component from 'vue-class-component';
import { State, Mutation, namespace } from 'vuex-class';
import { Route } from 'vue-router';

import logger, { isSentryEnabled } from '@/logger';
import UserDashboard from '@/views/user/Dashboard.vue';
import FlashMessage from '@/components/molecules/FlashMessage.vue';
import { FlashMessage as FlashMessageState } from '@/store/flashMessage';
import { TenantDetails, AuthToken, Idp, UserDetails } from '@/store/types/modules';
import { displayError, displayWarn } from '@/helpers/message';
import TenantHelper, { emptyTenantsError } from '@/helpers/tenant';
import UserHelper from '@/helpers/user';
import { isLoggedIn, resetAuth } from '@/helpers/auth';
import { updateLanguage } from '@/language';
import { hasFullscreenElem } from '@/store/view';
import { revalidateAndRefreshAuthToken } from './helpers/authToken';

const firebase = namespace('firebase');

const view = namespace('view');

const user = namespace('user');

@Component({
  components: {
    UserDashboard,
    FlashMessage,
  },
})
export default class App extends Vue {
  isLoading = true;

  @State('route') route!: Route;

  @State('flashMessage') flashMessage!: FlashMessageState;

  @State('authToken') authToken!: AuthToken;

  @State('idp') idp!: Idp;

  @State('user') user!: UserDetails;

  get tenantHelper(): TenantHelper {
    return new TenantHelper(this);
  }

  @Mutation('setTenantDetails') setTenantDetails!: (t: TenantDetails) => void;

  @user.Mutation('setUserDetails') setUserDetails!: (t: UserDetails) => void;

  @firebase.Action('setToken') setFCMToken!: (token: string) => void;

  @firebase.Action('removeToken') removeFCMToken!: () => void;

  @view.State('isPublicDisplayView') isPublicDisplayView!: boolean;

  @view.Action('reset') resetView!: () => void;

  get userHelper(): UserHelper {
    return new UserHelper(this);
  }

  async loadInformation(): Promise<void> {
    let tenant: TenantDetails;
    let currentUser: UserDetails;

    try {
      currentUser = await this.userHelper.getUserDetails();
    } catch (e) {
      logger.error('Failed to get user', e);
      displayError(this.$gettext('Failed to get user'));
      return;
    }

    try {
      tenant = await this.tenantHelper.getTenantDetails();
      // user is the actual one. this.user at this point is the one in the persistant cache
      if (currentUser.accountId !== this.user.accountId) {
        logger.debug('A different user signed in.');
        tenant.current = { ...tenant.allowedTenants[0] };
        this.resetView();
      }
    } catch (e) {
      if (e.message === emptyTenantsError) {
        logger.warn(e);
        const msg = this.$gettext('Your account cannot be found in any organizations. Please contact your admin to confirm your account setup.');
        displayWarn(msg);
        return;
      }
      logger.error('Failed to get tenant', e);
      displayError(this.$gettext('Failed to get tenant'));
      setTimeout(resetAuth, 2000);
      return;
    }

    this.setTenantDetails(tenant);

    this.setUserDetails(currentUser);

    const [ email = '' ] = currentUser.accountEmails || [];

    // update locale if needed
    if (currentUser.accountLanguage !== Vue.config.language) {
      updateLanguage(currentUser.accountLanguage); // still update local storage for language detection before user logs in
      Vue.config.language = currentUser.accountLanguage;
    }

    if (isSentryEnabled()) {
      const { expiresAt , issuer, subject } = this.authToken;
      Sentry.configureScope(scope => {
        scope.setTags({
          'authToken.expiresAt': expiresAt?.toString() || '',
          'authToken.issuer': issuer || '',
          'authToken.subjet': subject || '',
          'user.id': currentUser.accountId,
          'user.email': email,
        });
        scope.setUser({
          email,
          id: currentUser.accountId,
        });
        scope.setExtras({
          'state.idp': _.mapValues(this.idp, (value, key) =>
            key === 'idToken' ? '<filtered>' : value,
          ),
          'state.authToken': _.mapValues(this.authToken, (value, key) =>
            key === 'token' ? '<filtered>' : value,
          ),
          'state.user': currentUser,
          'state.route': _.pick(this.route, ['fullPath', 'params', 'meta', 'redirectedFrom']),
        });
      });
    }
  }

  async created(): Promise<void> {
    logger.info(`App version is ${process.env.VUE_APP_VERSION}`);

    if (isLoggedIn()) {
      logger.info('User is logged in');
      this.setTokenTimeout();
      await this.loadInformation();
    }

    this.isLoading = false;
    if ((this as any).$firebaseMessaging){
      this.initializeFCM();
    }
  }

  initializeFCM(): void {
    const messaging = (this as any).$firebaseMessaging;

    if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_FIREBASE_VAPID_KEY) {
      messaging.usePublicVapidKey(process.env.VUE_APP_FIREBASE_VAPID_KEY);

      // Request Permission of Notifications
      messaging.requestPermission().then(() => {
        // Get Token
        if (messaging) {
          messaging.getToken().then((token: any) => {
            this.setFCMToken(token);
          });

          // Retrieve an instance of Firebase Messaging so that it can handle background messages.
          messaging.onMessage((payload: any) => {
            if (window.Notification.permission === 'granted') {
              if (payload.data?.tag !== process.env.VUE_APP_VERSION) {
                let notification: Notification;
                if (this.isPublicDisplayView) {
                  if (hasFullscreenElem()) {
                    localStorage.setItem('publicModeFullScreen', 'true');
                  } else {
                    localStorage.setItem('publicMode', 'true');
                  }
                  notification = new Notification('A new version of the app is available',
                    { body: this.$gettext('Please click here to refresh the page or it will reload automatically in 10 seconds.'),
                      icon: '/img/icons/apple-touch-icon-180x180.png',
                    });
                  setTimeout(()=>{window.location.reload(true);}, 10000);
                } else {
                  notification = new Notification('A newer version of the app is available',
                    { body: this.$gettext('Please click here to refresh the page.'),
                      icon: '/img/icons/apple-touch-icon-180x180.png',
                      requireInteraction: true
                    });
                }
                notification.onclick = (event) :void => {
                  event.preventDefault(); // prevent the browser from focusing the Notification's tab
                  window.location.reload(true);
                  notification.close();
                };
              }
            }
          });
        }
      }).catch((err: any) => {
        // Suppress the error if the user decided to block the notification
        if (window.Notification.permission === 'denied') {
          logger.info('User blocked push notifications.');
        } else {
          logger.error('Unable to get permission to notify.', err);
        }
        this.removeFCMToken();
      });

      // Callback fired if Instance ID token is updated.
      if (messaging) {
        messaging.onTokenRefresh(() => {
          messaging.getToken().then((refreshedToken: any) => {
            logger.info('Token refreshed.');
            // Indicate that the new Instance ID token has not yet been sent to the
            // app server.
            this.removeFCMToken();
            // Send Instance ID token to app server.
            this.setFCMToken(refreshedToken);
          // ...
          }).catch((err: any) => {
            logger.error('Unable to retrieve refreshed token ', err);
          });
        });
      }
    }
  }

  get authTokenExpiresIn(): number {
    if (!this.authToken.expiresAt) {
      logger.error('expiresAt is undefined for the token');
      const eightHours = 8 * 1000 * 60 * 60;
      return eightHours;
    }
    const fixedExpiresAt = this.authToken.expiresAt * 1000;
    const timeDifference = fixedExpiresAt - new Date().getTime();
    const oneMinute = 60000;
    const oneHour = 60 * oneMinute;
    return Math.floor(timeDifference - oneHour);
  }

  setTokenTimeout(): void {
    setTimeout(
      async () => {
        await revalidateAndRefreshAuthToken();
        this.setTokenTimeout();
      },
      this.authTokenExpiresIn
    );
  }
}
