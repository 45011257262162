import { Configuration, FetchParams, RequestContext } from '../api/runtime';

const basePath = process.env.VUE_APP_API_BASE_PATH || '';

const apiConfiguration = new Configuration({
  basePath,
  middleware: [
    {
      async pre(context: RequestContext): Promise<FetchParams> {
        return {
          url: context.url,
          init: { ...context.init, ...{ credentials: 'include' } },
        };
      },
    },
  ],
});


export default apiConfiguration;
