/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EventAttendee,
    EventAttendeeFromJSON,
    EventAttendeeFromJSONTyped,
    EventAttendeeToJSON,
    EventBaseAttendee,
    EventBaseAttendeeFromJSON,
    EventBaseAttendeeFromJSONTyped,
    EventBaseAttendeeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    summary?: string;
    /**
     * 
     * @type {Date}
     * @memberof Event
     */
    start_time: Date;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    start_time_tz?: string;
    /**
     * 
     * @type {Date}
     * @memberof Event
     */
    end_time: Date;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    end_time_tz?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Event
     */
    is_all_day?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    organizer_name?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    organizer_email?: string;
    /**
     * 
     * @type {number}
     * @memberof Event
     */
    attendees_count?: number;
    /**
     * 
     * @type {EventBaseAttendee}
     * @memberof Event
     */
    organizer?: EventBaseAttendee;
    /**
     * 
     * @type {EventBaseAttendee}
     * @memberof Event
     */
    creator?: EventBaseAttendee;
    /**
     * 
     * @type {Array<EventAttendee>}
     * @memberof Event
     */
    attendees?: Array<EventAttendee>;
    /**
     * 
     * @type {Date}
     * @memberof Event
     */
    updated?: Date;
}

export function EventFromJSON(json: any): Event {
    return EventFromJSONTyped(json, false);
}

export function EventFromJSONTyped(json: any, ignoreDiscriminator: boolean): Event {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'start_time': (new Date(json['start_time'])),
        'start_time_tz': !exists(json, 'start_time_tz') ? undefined : json['start_time_tz'],
        'end_time': (new Date(json['end_time'])),
        'end_time_tz': !exists(json, 'end_time_tz') ? undefined : json['end_time_tz'],
        'is_all_day': !exists(json, 'is_all_day') ? undefined : json['is_all_day'],
        'organizer_name': !exists(json, 'organizer_name') ? undefined : json['organizer_name'],
        'organizer_email': !exists(json, 'organizer_email') ? undefined : json['organizer_email'],
        'attendees_count': !exists(json, 'attendees_count') ? undefined : json['attendees_count'],
        'organizer': !exists(json, 'organizer') ? undefined : EventBaseAttendeeFromJSON(json['organizer']),
        'creator': !exists(json, 'creator') ? undefined : EventBaseAttendeeFromJSON(json['creator']),
        'attendees': !exists(json, 'attendees') ? undefined : ((json['attendees'] as Array<any>).map(EventAttendeeFromJSON)),
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
    };
}

export function EventToJSON(value?: Event | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'summary': value.summary,
        'start_time': (value.start_time.toISOString()),
        'start_time_tz': value.start_time_tz,
        'end_time': (value.end_time.toISOString()),
        'end_time_tz': value.end_time_tz,
        'is_all_day': value.is_all_day,
        'organizer_name': value.organizer_name,
        'organizer_email': value.organizer_email,
        'attendees_count': value.attendees_count,
        'organizer': EventBaseAttendeeToJSON(value.organizer),
        'creator': EventBaseAttendeeToJSON(value.creator),
        'attendees': value.attendees === undefined ? undefined : ((value.attendees as Array<any>).map(EventAttendeeToJSON)),
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
    };
}


