/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Event,
    EventFromJSON,
    EventFromJSONTyped,
    EventToJSON,
} from './';

/**
 * 
 * @export
 * @interface FacilityEvent
 */
export interface FacilityEvent {
    /**
     * 
     * @type {string}
     * @memberof FacilityEvent
     */
    facility_id: string;
    /**
     * 
     * @type {Array<Event>}
     * @memberof FacilityEvent
     */
    events: Array<Event>;
    /**
     * 
     * @type {boolean}
     * @memberof FacilityEvent
     */
    active: boolean;
}

export function FacilityEventFromJSON(json: any): FacilityEvent {
    return FacilityEventFromJSONTyped(json, false);
}

export function FacilityEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): FacilityEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facility_id': json['facility_id'],
        'events': ((json['events'] as Array<any>).map(EventFromJSON)),
        'active': json['active'],
    };
}

export function FacilityEventToJSON(value?: FacilityEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'facility_id': value.facility_id,
        'events': ((value.events as Array<any>).map(EventToJSON)),
        'active': value.active,
    };
}


