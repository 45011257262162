import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CloseIcon from '@/assets/images/icon-close.svg';

@Component({
  components: { CloseIcon },
})
export default class ExpandModal extends Vue {
  @Prop({ type: Boolean }) show!: boolean;
}
