import Vue from 'vue';
import Component from 'vue-class-component';

export interface NaviItem {
  // itemKey is used to specify which item is in `item` slot.
  itemKey: string;

  [key: string]: any;
}

@Component
export default class Navibar extends Vue {}
