import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

type LoadingTheme = 'short';

@Component
export default class Loading extends Vue {
  @Prop({ type: String }) message!: string;

  @Prop({ type: Boolean, default: false }) short!: boolean;

  @Prop({ type: String, default: 'black' }) color!: 'black' | 'white';

  get showMessage(): boolean {
    return !!this.message && this.message.length !== 0 && !this.short;
  }
}
