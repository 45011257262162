import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { SettingsQuery } from '@/store/types/modules';
import ExpandModal from '@/components/templates/ExpandModal.vue';
import ConfirmationFooter from '@/components/molecules/ConfirmationFooter.vue';

const view = namespace('view');

@Component({
  components: { ExpandModal, ConfirmationFooter },
})
export default class FullscreenConfirmation extends Vue {
  @Prop({ type: Boolean }) show!: boolean;

  @view.State('settingsQuery') settingsQuery!: SettingsQuery;

  // The Action automatically goes to fullscreen but the mutation doesn't
  @view.Action('setPublicDisplayView') viewSetPublicDisplayViewAction!: (isPublicDisplayView: boolean) => void;

  @view.Mutation('setPublicDisplayView') viewSetPublicDisplayViewMutation!: (isPublicDisplayView: boolean) => void;

  async redirect(): Promise<void> {
    if (this.$route.name !== 'facilitiesSignage') {
      const cachedUrlQuery = this.settingsQuery.facilitiesSignage;
      await this.$router.push({
        name: 'facilitiesSignage',
        query: { settings: cachedUrlQuery ? btoa(JSON.stringify(cachedUrlQuery)) : '' }
      });
    }
  }

  async goFullscreenPublicMode(): Promise<void> {;
    await this.redirect();
    this.viewSetPublicDisplayViewAction(true);
    this.$emit('close');
  }

  async goDefaultPublicMode(): Promise<void> {
    await this.redirect();
    this.viewSetPublicDisplayViewMutation(true);
    this.$emit('close');
  }
}
