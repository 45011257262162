/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Tenant
 */
export interface Tenant {
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    timezone: string;
    /**
     * 
     * @type {string}
     * @memberof Tenant
     */
    language: string;
    /**
     * 
     * @type {Date}
     * @memberof Tenant
     */
    updated?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Tenant
     */
    active: boolean;
}

export function TenantFromJSON(json: any): Tenant {
    return TenantFromJSONTyped(json, false);
}

export function TenantFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tenant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'timezone': json['timezone'],
        'language': json['language'],
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'active': json['active'],
    };
}

export function TenantToJSON(value?: Tenant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'timezone': value.timezone,
        'language': value.language,
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'active': value.active,
    };
}


