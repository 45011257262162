import { render, staticRenderFns } from "./Dropdown.vue?vue&type=template&id=5c2b1aa8&scoped=true&"
import script from "./Dropdown.vue.ts?vue&type=script&lang=ts&"
export * from "./Dropdown.vue.ts?vue&type=script&lang=ts&"
import style0 from "./Dropdown.vue?vue&type=style&index=0&id=5c2b1aa8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c2b1aa8",
  null
  
)

export default component.exports