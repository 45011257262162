import { Module } from 'vuex';
import { RootState } from '@/store/types/rootState';
import { LoginParams } from '@/store/types/modules';

const loginModule: Module<LoginParams, RootState> = {
  state: { url: '' },
  mutations: {
    setLoginParams(state: LoginParams, l: LoginParams): void {
      state.url = l.url;
    },
  },
};

export default loginModule;
