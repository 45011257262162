/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FacilityFeatures
 */
export interface FacilityFeatures {
    /**
     * 
     * @type {string}
     * @memberof FacilityFeatures
     */
    building?: string;
    /**
     * 
     * @type {string}
     * @memberof FacilityFeatures
     */
    capacity?: string;
    /**
     * 
     * @type {string}
     * @memberof FacilityFeatures
     */
    floor?: string;
    /**
     * 
     * @type {string}
     * @memberof FacilityFeatures
     */
    fullname?: string;
}

export function FacilityFeaturesFromJSON(json: any): FacilityFeatures {
    return FacilityFeaturesFromJSONTyped(json, false);
}

export function FacilityFeaturesFromJSONTyped(json: any, ignoreDiscriminator: boolean): FacilityFeatures {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'building': !exists(json, 'building') ? undefined : json['building'],
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'fullname': !exists(json, 'fullname') ? undefined : json['fullname'],
    };
}

export function FacilityFeaturesToJSON(value?: FacilityFeatures | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'building': value.building,
        'capacity': value.capacity,
        'floor': value.floor,
        'fullname': value.fullname,
    };
}


