/**
 * Message component helper
 *
 * @desc helps do CRUD in components for flash messages
 */

import { Message, MessageTheme } from '@/store/flashMessage';
import store from '@/store';

interface MessageDisplayOptions {
  // theme is UI theme. - `slim` by default
  theme?: MessageTheme;

  // isFlashed enables whether message flashs on UI.
  // If `false`, message is hold then there is nowhere to click
  // until close button is clicked. - `true` by default
  isFlashed?: boolean;

  // isStacked enables whether message is stacked
  // without removing other messages. - `false` by default
  isStacked?: boolean;
}

function removeMessage(text: string): void {
  store.commit('removeMessage', text);
}

function resetMessages(): void {
  store.commit('resetMessages');
}

function addMessage(message: Message, options?: MessageDisplayOptions): void {
  let [isStacked, isFlashed] = [false, true];
  if (options) {
    isStacked = typeof options.isStacked === 'undefined' ? false : options.isStacked;
    isFlashed = typeof options.isFlashed === 'undefined' ? true : options.isFlashed;
  }

  if (!isStacked) {
    resetMessages();
  }

  let storeOptions = { isFlashed };
  if (options && options.theme) {
    storeOptions = { ...storeOptions, ...{ theme: options.theme } };
  }
  store.commit('addMessage', { ...message, ...storeOptions });

  if (!isFlashed) {
    return;
  }

  setTimeout(() => {
    removeMessage(message.text);
  }, 3000);
}

export function displayInfo(text: string, options?: MessageDisplayOptions): void {
  addMessage({ text, status: 'info' }, options);
}

export function displaySuccess(text: string, options?: MessageDisplayOptions): void {
  addMessage({ text, status: 'success' }, options);
}

export function displayWarn(text: string, options?: MessageDisplayOptions): void {
  addMessage({ text, status: 'warning' }, options);
}

export function displayError(text: string, options?: MessageDisplayOptions): void {
  addMessage({ text, status: 'error' }, options);
}

export function displayWait(text: string, options?: MessageDisplayOptions): void {
  addMessage({ text, status: 'wait' }, options);
}
