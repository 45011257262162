










































































































































































































































































import Vue, { Component as VueComponent } from 'vue';
import Component from 'vue-class-component';
import { State, namespace } from 'vuex-class';
import { Prop } from 'vue-property-decorator';
import { Route, Location } from 'vue-router';
import kebabCase from 'lodash/kebabCase';
import format from 'date-fns/format';

import { Base64 } from 'js-base64';
import Navibar from '@/components/molecules/Navibar.vue';
import Dropdown, { DropdownItem } from '@/components/molecules/Dropdown.vue';
import UserSettings from '@/components/organisms/UserSettings.vue';
import SignageSettings from '@/components/organisms/SignageSettings.vue';
import FullscreenConfirmation from '@/components/organisms/FullscreenConfirmation.vue';

import LogoIcon from '@/assets/images/logo-white.svg';
import LeftArrowIcon from '@/assets/images/icon-arrow-left.svg';
import DownArrowIcon from '@/assets/images/icon-arrow-down.svg';
import ExternalLinkIcon from '@/assets/images/icon-link-external.svg';
import AccountMenuIcon from '@/assets/images/icon-menu-account.svg';
import BookingMenuIcon from '@/assets/images/icon-menu-booking.svg';
import SignageMenuIcon from '@/assets/images/icon-menu-signage.svg';
import TabletMenuIcon from '@/assets/images/icon-menu-tablet.svg';
import GhostIcon from '@/assets/images/icon-ghost.svg';
import SuccessIcon from '@/assets/images/success.svg';
import TenantHelper from '@/helpers/tenant';
import { TenantDetails, SettingsQuery } from '@/store/types/modules';
import { UserPreferences } from '@/store/userSettings';
import { UserTenantRole } from '@/api/models';

const userSettings = namespace('userSettings');
const signageSettings = namespace('signageSettings');
const view = namespace('view');
const user = namespace('user');

const MENU_ICON_TABLE: { [key: string]: VueComponent } = {
  bookingFacility: BookingMenuIcon,
  facilitiesSignage: SignageMenuIcon,
  facilityDashboardLinks: TabletMenuIcon, // TODO: update icon
};

export interface MenuLinkItem {
  id: string;
  name: string;
  location: Location;
  aliasIds?: string[];
}

Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

@Component({
  components: {
    Navibar,
    Dropdown,
    LogoIcon,
    LeftArrowIcon,
    DownArrowIcon,
    ExternalLinkIcon,
    AccountMenuIcon,
    SignageMenuIcon,
    BookingMenuIcon,
    TabletMenuIcon,
    SuccessIcon,
    GhostIcon,
    UserSettings,
    SignageSettings,
    FullscreenConfirmation,
  },
})
export default class Dashboard extends Vue {
  date = '';

  time = '';

  currentMenu = '';

  private adminURL = process.env.VUE_APP_FRONTEND_ADMIN_ENDPOINT;

  private fullscreenConfirmationIsShown = false;

  settingDropdownKeys = [
    'profile',
    'switch-current-tenant',
    'user-settings',
    'signage-settings',
    // TODO: Enable admin link after implementing admin dashboard and role based access controll
    // 'go-to-admin',
    'switch-public-mode',
    'logout',
  ];

  @State('tenant') tenant!: TenantDetails;

  @State('route') route!: Route;

  @view.State('settingsQuery') settingsQuery!: SettingsQuery;

  @view.State('isPublicDisplayView') isPublicDisplayView!: boolean;

  @view.Action('setPublicDisplayView') viewSetPublicDisplayView!: (
    isPublicDisplayView: boolean,
  ) => void;

  @userSettings.State('isShown') userSettingsIsShown!: UserPreferences;

  @userSettings.Mutation('setIsShown') userSettingsSetIsShown!: (isShown: boolean) => void;

  @signageSettings.Mutation('setIsShown') signageSettingsSetIsShown!: (isShown: boolean) => void;

  @view.Mutation('resetSettingsQuery') resetSignageSettings!: () => void;

  @user.State('accountRoles') accountRoles!: UserTenantRole[];

  get settingDropdownItems(): DropdownItem[] {
    return this.settingDropdownKeys.reduce<DropdownItem[]>((acc, key) => {
      acc = [...acc, ...[{ itemKey: key }, { itemKey: 'stake' }]];
      return acc;
    }, []);
  }

  get tenantHelper(): TenantHelper {
    return new TenantHelper(this);
  }

  /**
   * `mode` selects a view mode.
   *
   * The view modes are `three-pain`, `fullscreen` and `signage`:
   * - `three-pain`: is used to set up something. For example, admin dashboard.
   *   This mode contains header and contents. In addition, the containts contains siedebar.
   * - `full-screen`: is similar to `three-pain` mode. In this mode, we can use large space.
   *   This mode is same as `three-pain` but sidebar uses hamburger button mode.
   * - `signage`: is used to show something. For example, facilities signage.
   *   This mode contains header, contents. However, the contents doesn't contain sidebar.
   *
   * @member {string} mode 'three-pain' | 'fullscreen' | 'signage'
   */
  @Prop({ type: String, default: 'signage' })
  mode!: 'three-pain' | 'fullscreen' | 'signage';

  get hasTabletViewPermission(): boolean {
    const tabletViewRoles = ['operator', 'admin', 'public'];
    return this.accountRoles.some(
      (role) => role.tenant_id === this.tenant.current.id && tabletViewRoles.includes(role.role),
    );
  }

  get menuLinkTable(): { [id: string]: MenuLinkItem } {
    const cachedUrlQuery = this.settingsQuery.facilitiesSignage;
    const facilitiesSignageQuery = cachedUrlQuery
      ? Base64.encode(JSON.stringify(cachedUrlQuery))
      : '';
    const menuLinkTable = {
      bookingFacility: {
        id: 'bookingFacility',
        name: this.$gettext('Instant Facility Booking'),
        location: { name: 'bookingFacility' },
      },
      facilitiesSignage: {
        id: 'facilitiesSignage',
        name: this.$gettext('Signage'),
        location: { name: 'facilitiesSignage', query: { settings: facilitiesSignageQuery } },
        aliasIds: ['facilitiesSignage'],
      },
    };

    if (this.hasTabletViewPermission) {
      Object.assign(menuLinkTable, {
        facilityDashboardLinks: {
          id: 'facilityDashboardLinks',
          name: this.$gettext('Facility Tablet'),
          location: { name: 'facilityDashboardLinks' },
        },
      });
    }

    return menuLinkTable;
  }

  get tenantItems(): DropdownItem[] {
    return this.tenantHelper.allowedTenants().map((tenant) => {
      return { ...{ tenant }, ...{ itemKey: `switch-tenant-${tenant.id}` } } as DropdownItem;
    });
  }

  get menuDropdownItems(): DropdownItem[] {
    const menuDropdownItems = [{ itemKey: 'bookingFacility' }, { itemKey: 'facilitiesSignage' }];
    if (this.hasTabletViewPermission) {
      menuDropdownItems.push({ itemKey: 'facilityDashboardLinks' });
    }
    return menuDropdownItems;
  }

  get selectableTenants(): DropdownItem[] {
    return this.tenantItems.length > 1 ? this.tenantItems : []; // When there's less than 2 tenant items, the tenant selection sub-menu is disabled, so no need to render.
  }

  async mounted(): Promise<void> {
    this.updateDateTime();
    setInterval(this.updateDateTime, 1000);
  }

  menuIconComponent(itemKey: string): VueComponent {
    return MENU_ICON_TABLE[itemKey];
  }

  kebabCase(s: string): string {
    return kebabCase(s);
  }

  updateDateTime(): void {
    const nowDate = new Date();
    this.date = format(nowDate, 'MMM d');
    this.time = format(nowDate, 'HH:mm');
  }

  isActiveMenu(item: MenuLinkItem): boolean {
    if (this.route.name === item.id) {
      return true;
    }

    if (!item.aliasIds) {
      return false;
    }

    return item.aliasIds.some((childId) => {
      return this.route.name === childId;
    });
  }

  async goPublicView(close: () => void): Promise<void> {
    this.fullscreenConfirmationIsShown = true;
    close();
  }
}
