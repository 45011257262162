/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventAttendee
 */
export interface EventAttendee {
    /**
     * 
     * @type {string}
     * @memberof EventAttendee
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof EventAttendee
     */
    display_name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EventAttendee
     */
    optional?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EventAttendee
     */
    is_resource?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EventAttendee
     */
    response_status?: EventAttendeeResponseStatusEnum;
}

export function EventAttendeeFromJSON(json: any): EventAttendee {
    return EventAttendeeFromJSONTyped(json, false);
}

export function EventAttendeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventAttendee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'display_name': !exists(json, 'display_name') ? undefined : json['display_name'],
        'optional': !exists(json, 'optional') ? undefined : json['optional'],
        'is_resource': !exists(json, 'is_resource') ? undefined : json['is_resource'],
        'response_status': !exists(json, 'response_status') ? undefined : json['response_status'],
    };
}

export function EventAttendeeToJSON(value?: EventAttendee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'display_name': value.display_name,
        'optional': value.optional,
        'is_resource': value.is_resource,
        'response_status': value.response_status,
    };
}

/**
* @export
* @enum {string}
*/
export enum EventAttendeeResponseStatusEnum {
    None = 'none',
    True = 'true',
    False = 'false',
    Maybe = 'maybe'
}


