import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { RootState } from '@/store/types/rootState';
import signageSettings from './signageSettings';
import facilityView from './facilityView';
import login from './login';
import idp from './idp';
import safariPatch from './safariPatch';
import authToken from './authToken';
import tenant from './tenant';
import user from './user';
import view from './view';
import flashMessage from './flashMessage';
import bookedFacility from './bookedFacility';
import userSettings from './userSettings';
import firebase from './firebase';

const PERSISTED_KEY = 'hennge-rooms-state';
const PERSISTED_MODULES = {
  login,
  idp,
  authToken,
  safariPatch,
  tenant,
  view,
  bookedFacility,
  user,
};
const PRESISTED_MODULES_KEYS = Object.keys(PERSISTED_MODULES);

Vue.use(Vuex);

const persistPlugin = createPersistedState({
  key: PERSISTED_KEY,
  paths: PRESISTED_MODULES_KEYS,
});

const defaultStore: StoreOptions<RootState> = {
  modules: { ...PERSISTED_MODULES, ...{ userSettings, signageSettings, flashMessage, firebase, facilityView } },
  plugins: [persistPlugin],
};
export default new Vuex.Store<RootState>(defaultStore);
