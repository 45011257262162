import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import CircleLoadingAnime from '@/assets/images/loading-circle.svg';

@Component({ components: { CircleLoadingAnime } })
export default class CircleLoadding extends Vue {
  @Prop({ type: String }) public text!: string;

  @Prop({ type: String, default: 'white' }) public color!: 'black' | 'white';
}
