import { Module } from 'vuex';
import { RootState } from '@/store/types/rootState';
import { TenantDetails } from '@/store/types/modules';

import { Tenant } from '@/api';

export const DefaultCurrentTenant = {
  id: 'default_id',
  name: 'default_name',
  timezone: 'Asia/Tokyo',
  language: 'ja',
  active: false
};

const tenantModule: Module<TenantDetails, RootState> = {
  state: { allowedTenants: [], current: DefaultCurrentTenant },
  mutations: {
    setTenantDetails(state: TenantDetails, t: TenantDetails): void {
      state.current = { ...t.current };
      state.allowedTenants = [...t.allowedTenants];
    },
    setCurrentTenant(state: TenantDetails, tenant: Tenant): void {
      state.current = { ...tenant };
    },
  },
  actions: {
    setCurrentTenant(context, tenant: Tenant): void {
      context.commit('setCurrentTenant', tenant);
      context.commit('view/resetSettingsQuery', tenant);
    },
  }
};

export default tenantModule;
