import { render, staticRenderFns } from "./OverlayModal.vue?vue&type=template&id=ec8c37d2&scoped=true&"
import script from "./OverlayModal.vue.ts?vue&type=script&lang=ts&"
export * from "./OverlayModal.vue.ts?vue&type=script&lang=ts&"
import style0 from "./OverlayModal.vue?vue&type=style&index=0&id=ec8c37d2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ec8c37d2",
  null
  
)

export default component.exports