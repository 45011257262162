import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import SuccessIcon from '@/assets/images/icon-thumbs-up.svg';
import InfoIcon from '@/assets/images/icon-info.svg';
import ErrorIcon from '@/assets/images/icon-error.svg';
import SuccessSquireIcon from '@/assets/images/icon-success.svg';
import WarningIcon from '@/assets/images/icon-warning.svg';

import CloseThinIcon from '@/assets/images/icon-close-thin.svg';
import CircleLoadingAnime from '@/components/atoms/CircleLoading.vue';
import { Message, MessageStatus } from '@/store/flashMessage';

export const FLASH_MESSAGE_CLOSE_DURATION = 3000;
@Component({
  components: {
    CloseThinIcon,
    CircleLoadingAnime,
    SuccessSlimIcon: SuccessIcon,
    InfoSlimIcon: InfoIcon,
    WarningSlimIcon: WarningIcon,
    WaitSlimIcon: InfoIcon,
    ErrorSlimIcon: ErrorIcon,
    SuccessSquireIcon,
    InfoSquireIcon: InfoIcon,
    WarningSquireIcon: WarningIcon,
    ErrorSquireIcon: ErrorIcon,
    WaitSquireIcon: InfoIcon,
  },
})
export default class FlashMessage extends Vue {
  @Prop({ type: Array, default: (): Message[] => [] }) messages!: Message[];

  // FIXME: Use hash key like md5 instad of text if performance problem occurs
  opened: { [text: string]: boolean } = {};

  slimIcon(status: MessageStatus): string {
    return `${this.statusName(status)}SlimIcon`;
  }

  squireIcon(status: MessageStatus): string {
    return `${this.statusName(status)}SquireIcon`;
  }

  statusName(status: MessageStatus): string {
    return status.replace(/(^.)/, (c: string): string => c.toUpperCase());
  }

  isOpened(message: Message): boolean {
    return this.opened[message.text] || false;
  }

  isFlashed(message: Message): boolean {
    if (typeof message.isFlashed === 'undefined') {
      return true;
    }
    return message.isFlashed;
  }

  mounted(): void {
    this.mount();
  }

  @Watch('messages')
  private mount(): void {
    this.opened = this.messages.reduce(
      (acc, msg) => {
        const status = this.opened[msg.text] ? this.opened[msg.text] : true;
        acc[msg.text] = status;
        return acc;
      },
      {} as { [text: string]: boolean },
    );

    setTimeout(() => {
      this.messages.forEach(msg => {
        if (!msg.isFlashed) {
          return;
        }
        this.close(msg);
      });
    }, FLASH_MESSAGE_CLOSE_DURATION);
  }

  close(message: Message): void {
    this.opened[message.text] = this.opened[message.text] && false;
  }
}
