/**
 * IM integration component helper
 *
 * @desc helps do CRUD in components for IM integration
 */
import { Store } from 'vuex';

import logger from '@/logger';
import { IMUserPreference, IMIntegrationSummaryImProviderIdEnum } from '@/api/models';
import { ImIntegrationsApi } from '@/api/apis/ImIntegrationsApi';
import apiConfiguration from '@/helpers/config';
import { authMiddleware } from '@/helpers/authToken';
import TenantHelper from '@/helpers/tenant';
import { UiImIntegration } from '@/models/imIntegration';
import { AuthToken , Idp , TenantDetails , BookedFacility } from '@/store/types/modules';
import { FlashMessage } from '@/store/flashMessage';

interface ImIntegrationsHelperDeps {
  $gettext: (msgid: string) => string;
  $store: Store<{
    authToken: AuthToken;
    flashMessage: FlashMessage;
    idp: Idp;
    tenant: TenantDetails;
    bookedFacility: BookedFacility;
  }>;
}

interface ImIntegrationsHelperAssists {
  tenant: TenantHelper;
}

export default class ImIntegrationsHelper {
  vue: ImIntegrationsHelperDeps;

  helper: ImIntegrationsHelperAssists;

  constructor(deps: ImIntegrationsHelperDeps) {
    this.vue = deps;
    this.helper = {
      tenant: new TenantHelper(deps),
    };
  }

  get imProviderNameTable(): { [key: string]: string } {
    return {
      slack: 'Slack',
      email: this.vue.$gettext('Email'),
      teams: 'Teams',
      hangouts: 'Hangouts Chat',
    };
  }

  imProviderName(id: string): string {
    return this.imProviderNameTable[id];
  }

  async fetchImIntegrations(tenantId: string): Promise<UiImIntegration[]> {
    logger.info('Tenant IM integrations are is fetching...');

    const imIntegApi = new ImIntegrationsApi(apiConfiguration)
      .withMiddleware(authMiddleware);
    const integrations = await imIntegApi
      .getTenantIMIntegrations({ tenantId })
      .then(integrationsResult => integrationsResult.filter(integ => integ.active));

    logger.info('Tenant IM integrations have been fetched');

    const fetchPreferencesPerIntegration = integrations.map(integ =>
      imIntegApi.getIMUserPreference({
        tenantId,
        imIntegrationId: integ.im_integration_id,
      }),
    );

    logger.info('IM user preferences are fetching...');

    const userPreferences = await Promise.all(fetchPreferencesPerIntegration);

    logger.info('IM user preferences have been fetched');

    return integrations.map(
      (integ, index) =>
        ({
          ...integ,
          ...userPreferences[index],
        } as UiImIntegration),
    );
  }

  async fetchImIntegration(
    tenantId: string,
    imProviderId: IMIntegrationSummaryImProviderIdEnum,
    imIntegrationId: string,
  ): Promise<UiImIntegration | null> {
    logger.info('Fetching IM user preference...');

    const imIntegApi = new ImIntegrationsApi(apiConfiguration)
      .withMiddleware(authMiddleware);

    const result = await imIntegApi.getIMUserPreference({
      tenantId,
      imIntegrationId,
    });
    const model = result as UiImIntegration;

    logger.info('IM user preference has been fetched', model);
    return {
      ...model,
      ...{
        im_provider_id: imProviderId,
        im_integration_id: imIntegrationId,
      },
    } as UiImIntegration;
  }

  async createImIntegration(
    tenantId: string,
    imIntegrationId: string,
    model: UiImIntegration,
  ): Promise<void> {
    logger.info('IM user preference is updating...');
    await new ImIntegrationsApi(apiConfiguration)
      .withMiddleware(authMiddleware)
      .postIMUserPreference({
        tenantId,
        imIntegrationId,
        iMUserPreference: model as IMUserPreference,
      });
    logger.info('IM user preference has been updated');
  }
}
