/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Identity,
    IdentityFromJSON,
    IdentityFromJSONTyped,
    IdentityToJSON,
    UserTenantRole,
    UserTenantRoleFromJSON,
    UserTenantRoleFromJSONTyped,
    UserTenantRoleToJSON,
} from './';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    active: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    timezone: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    language: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    emails: Array<string>;
    /**
     * 
     * @type {Array<UserTenantRole>}
     * @memberof User
     */
    roles: Array<UserTenantRole>;
    /**
     * 
     * @type {Array<Identity>}
     * @memberof User
     */
    identities: Array<Identity>;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    updated?: Date;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'active': json['active'],
        'timezone': json['timezone'],
        'language': json['language'],
        'emails': json['emails'],
        'roles': ((json['roles'] as Array<any>).map(UserTenantRoleFromJSON)),
        'identities': ((json['identities'] as Array<any>).map(IdentityFromJSON)),
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'active': value.active,
        'timezone': value.timezone,
        'language': value.language,
        'emails': value.emails,
        'roles': ((value.roles as Array<any>).map(UserTenantRoleToJSON)),
        'identities': ((value.identities as Array<any>).map(IdentityToJSON)),
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
    };
}


