import logger from '@/logger';
import store from '@/store';
import { AuthenticateRequestIdpEnum } from '@/api/models';
import { FetchParams, RequestContext, ResponseContext, Middleware } from '@/api/runtime';
import { AuthToken } from '@/store/types/modules';
import { resetAuth, isLoggedIn, isIDPValid } from '@/helpers/auth';
import { AuthApi } from '@/api/apis/AuthApi';
import apiConfiguration from '@/helpers/config';
import { decodeIdToken, IdToken } from '@/oidc/jwt';

const needsToLoginError = "Needs to login";
const needsToRecreateError = "Needs to recreate token";

async function getNewAuthToken(): Promise<AuthToken> {
  const { idp } = store.state;
  const client = process.env.VUE_APP_DOMAIN_NAME || '';
  const authApi = new AuthApi(apiConfiguration);

  if (!idp || !isIDPValid(idp)) {
    logger.warn('ID provider is not valid');
    throw new Error(needsToLoginError);
  }

  logger.info('Creating auth token...');
  const reqParams = {
    client,
    idp: idp.idpName as AuthenticateRequestIdpEnum,
    token: idp.idToken || '',
  };

  try {
    const newPostedToken = await authApi.postAuthToken({ authenticateRequest: reqParams });
    const token = decodeIdToken<IdToken>(newPostedToken.token);
    const newAuthToken = {
      ...newPostedToken,
      ...{ expiresAt: token.exp, issuer: token.iss, subject: token.sub },
    };
    return newAuthToken;
  } catch (e) {
    logger.error('Failed to create auth token', { ...reqParams }, e);
    if (e instanceof Response) {
      if (e.status === 400 || e.status === 401) {
        logger.warn('Failed to keep auth token: Unauthorized');
        throw new Error(needsToLoginError);
      }
    };
    throw e;
  }
}

async function storeAuthToken(authToken: AuthToken): Promise<void> {
  store.commit('setAuthToken', authToken);
}

export async function createStoreAuthToken(): Promise<void> {
  try {
    const authToken = await getNewAuthToken();
    storeAuthToken(authToken);
  } catch (e) {
    if(e.message === needsToLoginError) {
      resetAuth();
      return;
    }
    throw e;
  }
}

function buildFetchParams(context: RequestContext): FetchParams {
  const { token } = store.state.authToken;

  const headers = {
    authorization: `Bearer ${token}`,
  };

  context.init.headers = { ...context.init.headers, ...headers };

  return {
    url: context.url,
    init: context.init,
  };
}

const updateTokenAuthMiddleware: Middleware = {
  async pre(context: RequestContext): Promise<FetchParams> {
    return buildFetchParams(context);
  },
};

async function getUpdatedAuthToken(): Promise<AuthToken> {
  let { authToken } = store.state;
  const authApi = new AuthApi(apiConfiguration).withMiddleware(updateTokenAuthMiddleware);

  logger.info('Updating auth token...', { ...authToken });
  try {
    authToken = await authApi.getAuthToken();
  } catch (e) {
    logger.error('Failed to update auth token', { ...authToken });
    throw new Error(needsToRecreateError);
  }

  const token = decodeIdToken(authToken.token);
  const newAuthToken = {
    ...authToken,
    ...{ expiresAt: token.exp, issuer: token.iss, subject: token.sub },
  };

  return newAuthToken;
}

async function refreshStoreAuthToken(): Promise<void> {
  try {
    const updatedToken = await getUpdatedAuthToken();
    storeAuthToken(updatedToken);
  } catch (e) {
    if (e.message === needsToRecreateError) {
      createStoreAuthToken();
      return;
    }
    throw e;
  }

}

/**
 * revalidateAndRefreshAuthToken Checks the token validity and recreates if needed
 *
 * If the auth token does not exists, the auth token is created with ID token from IDP.
 *
 * If the auth token exists and the auth token is not expired, the auth token is updated.
 *
 * If ID token from IDP is expired or the status of API request is 401,
 * current page is redirected into login page.
 *
 * @return {Promise<void>} Void promise object that fails if the auth token cannot be revalidated
 */
export async function revalidateAndRefreshAuthToken(): Promise<void> {
  const { idp } = store.state;

  logger.debug('Revalidating token');
  if (! idp || !isIDPValid(idp)) {
    logger.warn('Failed to keep auth token: ID provider is not valid', {
      expiresAt: idp?.expiresAt,
    });
    resetAuth();
    throw new Error('Failed to keep auth token: ID provider is not valid');
  }

  logger.info("Refreshing token");
  await refreshStoreAuthToken();
}

export const authMiddleware: Middleware = {
  async pre(context: RequestContext): Promise<FetchParams> {
    if (!isLoggedIn()) {
      logger.warn('Token is not valid for request');
      resetAuth();
      throw Error('Token is not valid for request');
    }
    return buildFetchParams(context);
  },

  async post(context: ResponseContext): Promise<Response> {
    const { status } = context.response;
    if (status >= 500) {
      logger.error('API request failed: Server error', context.response);
      throw context.response;
    }
    if(status >= 400 && status < 500) {
      logger.warn('API request failed: Client error', context.response);
      if (status === 401) {
        resetAuth();
      }
      throw context.response;
    }
    return context.response;
  }
};
