import { Module } from 'vuex';
import { differenceInMinutes } from 'date-fns';
import { RootState } from '@/store/types/rootState';
import { FacilityView } from '@/store/types/modules';
import { UiFacility } from '@/models/facility';
import { Event } from '@/api/models';
import { API_CACHE_MINUTES } from '@/env';

const facilityViewModule: Module<FacilityView, RootState> = {
  namespaced: true,
  state: {
    isTimelineActive: false,
    timelineTimeoutHandler: null,
    facility: undefined,
    cachedFacility: undefined,
    cachedShortenedEvents: [],
  },
  actions: {
    setTimeline({ commit }, isActive: boolean): void {
      commit('showTimeline', isActive);
      if (isActive) {
        const timeoutTimer = 60 * 1000; // 1 minute
        const timeout = setTimeout(() => {
          commit('showTimeline', false);
        }, timeoutTimer);
        commit('setTimelineTimeoutHandler', timeout);
        return;
      }
      commit('setTimelineTimeoutHandler', null);
    }
  },
  mutations: {
    showTimeline(state: FacilityView, isActive: boolean): void {
      state.isTimelineActive = isActive;
    },
    setTimelineTimeoutHandler(state: FacilityView, handler: NodeJS.Timeout | null): void {
      if (state.timelineTimeoutHandler) {
        clearTimeout(state.timelineTimeoutHandler);
      }
      state.timelineTimeoutHandler = handler;
    },
    setFacility(state: FacilityView, f: UiFacility): void {
      state.facility = f;
    },
    setCachedFacility(state: FacilityView, f: UiFacility): void {
      state.cachedFacility = f;
    },
    resetCachedFacility(state: FacilityView): void {
      state.cachedFacility = undefined;
    },
    pushCachedShortenedEvents(state: FacilityView, e: Event): void {
      state.cachedShortenedEvents.push(e);
    },
    removeExpiredShortenedEvents(state: FacilityView): void {
      const now = new Date();
      state.cachedShortenedEvents = state.cachedShortenedEvents.filter( event => (event.updated && differenceInMinutes(now, event.updated) <= API_CACHE_MINUTES));
    },
    resetCachedShortenedEvents(state: FacilityView): void {
      state.cachedShortenedEvents = [];
    },
  }
};


export default facilityViewModule;
