import { render, staticRenderFns } from "./ExpandModal.vue?vue&type=template&id=6e922444&scoped=true&"
import script from "./ExpandModal.vue.ts?vue&type=script&lang=ts&"
export * from "./ExpandModal.vue.ts?vue&type=script&lang=ts&"
import style0 from "./ExpandModal.vue?vue&type=style&index=0&id=6e922444&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e922444",
  null
  
)

export default component.exports