/**
 * Facility view model
 *
 * @desc represents view model of a facility on UI and functions to manages data of facility
 */
import { Facility, FacilityStatus, FacilityFeatures } from '@/api/models';
import {
  UiFacility,
  FacilityStatusNameEnum,
  FacilitySearchQuery,
  FacilityUrlQuery,
} from '@/models/facility';
import { sortByLocale } from '@/language';

const FACILITY_FEATURES = ['building', 'capacity', 'floor', 'fullname'];

export type UiFacilitySortCompareFunc = (a: UiFacility, b: UiFacility) => number;

/**
 * @name sortByFacilityName
 *
 * @desc Sort facilities by facility name
 * @param {String} order  (e.g. "asc", "desc")
 *
 * @return {Function} returns the function to compare facility name
 */
export function sortByFacilityName(order = 'asc'): UiFacilitySortCompareFunc {
  return (a, b): number => {
    const collator = new Intl.Collator([], { numeric: true, sensitivity: 'base' });
    if (!a.facility_name || !b.facility_name) {
      return -1;
    }

    if (order === 'desc') {
      return collator.compare(b.facility_name, a.facility_name);
    }

    return collator.compare(a.facility_name, b.facility_name);
  };
}

/**
 * @name sortByFacilityFeature
 *
 * @desc Sort facilities by facility feature
 * @param {String} feature  (e.g. "building")
 *
 * @return {Function} returns the function to create the comparing function for facility features
 */
export function sortByFacilityFeature(feature: keyof FacilityFeatures) {
  return (order = 'asc'): UiFacilitySortCompareFunc => (a, b): number => {
    const collator = new Intl.Collator([], { numeric: true, sensitivity: 'base' });
    const aFs = a.features;
    const bFs = b.features;
    if (!aFs || !bFs) {
      return -1;
    }

    const aF = aFs[feature] || '';
    const bF = bFs[feature] || '';

    if (order === 'desc') {
      return collator.compare(bF, aF);
    }

    return collator.compare(aF, bF);
  };
}

/**
 * @name facilityStatusName
 *
 * @desc get the status name of facility from the status of faciiltys.
 * @param {FacilityStatus} fs The facility status
 *
 * @return {FacilityStatusNameEnum} returns the status name of facility
 */
export function facilityStatusName(fs: FacilityStatus): FacilityStatusNameEnum {
  if (fs.is_facility_used) {
    if (fs.event) {
      return FacilityStatusNameEnum.Occupied;
    }
    return FacilityStatusNameEnum.NoReservation;
  }

  if (fs.event) {
    return FacilityStatusNameEnum.NoAttendees;
  }

  return FacilityStatusNameEnum.Vacant;
}

/**
 * @name filterByQueryParams
 *
 * @desc filter facilities with search query.
 * The search query supports features of facility
 * e.g. `building`, `capacity`, `floor` and `fullname`.
 * @param {[key: string]: string[]} query The search query for facility
 *
 * @return {Function} returns the array of filterd facility
 */
export function filterByQueryParams(query: FacilitySearchQuery): (f: Facility) => boolean {
  const queryKeys = Object.keys(query);

  return (facility: Facility): boolean => {
    const { features } = facility;
    if (!features) {
      return true;
    }

    let matched = true;

    queryKeys.forEach((queryKey: keyof FacilityFeatures): void => {
      // Validation
      if (FACILITY_FEATURES.indexOf(queryKey) === -1) {
        return;
      }

      const feature = features[queryKey];
      if (!feature) {
        return;
      }

      const queryValues = query[queryKey];
      if (!queryValues) {
        return;
      }


      const machedFacilities = queryValues.some(
        (queryValue) => queryValue.toLowerCase() === feature.toLowerCase(),
      );
      if (!machedFacilities) {
        matched = false;
      }
    });

    return matched;
  };
}

/**
 * @name prioritizeByFacilityStatus
 *
 * @desc Prioritize UI facilities by facility status.
 * e.g. ``
 * @param {FacilityStatusName} statusName  the name of facility status
 * @param {String} order  (e.g. "asc", "desc")
 *
 * @return {Function} returns the function to compare facility name
 */
export function prioritizeByFacilityStatus(
  statusName: FacilityStatusNameEnum,
  order = 'asc',
): UiFacilitySortCompareFunc {
  return (a, b): number => {
    if (!a.status_name || !b.status_name) {
      return -1;
    }

    // No need to sort
    if (a.status_name === b.status_name) {
      return 0;
    }

    if (order === 'desc') {
      return a.status_name === statusName ? 1 : -1;
    }

    return a.status_name === statusName ? -1 : 1;
  };
}

/**
 * @name splitFacilitiesByBooking
 *
 * @desc Splits faciliteis into occupied facilities and vacant facilities
 * @param {UiFacility[]} facilities The array of UI facility
 *
 * @return {[ UiFacility[], UiFacility[] ]} returns the array of occupied and vacant UI facility
 */
export function splitFacilitiesByBooking(facilities: UiFacility[]): [UiFacility[], UiFacility[]] {
  return facilities.reduce(
    ([occupiedFs, vacantFs], facility) => {
      const occupiedStatusNames = [
        FacilityStatusNameEnum.Occupied,
        FacilityStatusNameEnum.NoAttendees,
      ];
      if (facility.status_name && occupiedStatusNames.indexOf(facility.status_name) > -1) {
        occupiedFs.push(facility);
      } else {
        vacantFs.push(facility);
      }
      return [occupiedFs, vacantFs];
    },
    [[] as UiFacility[], [] as UiFacility[]],
  );
}

/**
 * @name splitFacilitiesByMyBooking
 *
 * @desc Splits facilities into facilities booked by myself and other facilities
 * @param {UiFacility[]} facilities The array of UI facility
 * @param {string[]} userEmails The array of my email address
 *
 * @return {[ UiFacility[], UiFacility[] ]} returns the pair of arraies of facilities booked by myself and other
 */
export function splitFacilitiesByMyBooking(
  facilities: UiFacility[],
  userEmails: string[],
): [UiFacility[], UiFacility[]] {
  return facilities.reduce(
    ([myFs, othersFs], facility) => {
      if (!userEmails || !facility.event || !facility.event.organizer_email) {
        othersFs.push(facility);
        return [myFs, othersFs];
      }

      if (userEmails.indexOf(facility.event.organizer_email) > -1) {
        myFs.push(facility);
      } else {
        othersFs.push(facility);
      }
      return [myFs, othersFs];
    },
    [[] as UiFacility[], [] as UiFacility[]],
  );
}

/**
 * @name mergeFacilitiesWithEventUpdatedTime
 *
 * @desc merge two arraies of facilities with updated time of facility event
 *  from first facilities to second facilities
 * @param {UiFacility[]} firstFacilities The first array of UI facility
 * @param {UiFacility[]} secondFacilities The second array of UI facility
 *
 * @return {UiFacility[]]} returns the merged array of UI facility
 */
export function mergeFacilitiesWithEventUpdatedTime(
  firstFacilities: UiFacility[],
  secondFacilities: UiFacility[],
): UiFacility[] {
  return secondFacilities.map((secondF) => {
    const mached = firstFacilities.filter((firstF) => {
      if (firstF.facility_id !== secondF.facility_id) {
        return false;
      }

      const firstE = firstF.event;
      const secondE = secondF.event;

      if (firstE && !secondE) {
        // If first event is set and second event is nothing, first event will be used.
        //
        // Concretly, a cached event on Tenri UI will be used after creating an event
        // because updating cache of event on Tenri API is slower when event is created.
        // The time for creating is several minutes.
        //
        // While, it's faster when event is deleted. The time for deleting is about 5 seconds.
        //
        // Threfore, a problem about deleting event isn't addressed on Tenri UI.
        return true;
      }

      if (!firstE || !secondE) {
        return false;
      }

      if (!firstE.updated || !secondE.updated) {
        return false;
      }

      if (firstE.updated <= secondE.updated) {
        return false;
      }

      return true;
    });

    return mached.length > 0 ? mached[0] : secondF;
  });
}

export function isAvailableFacility(facility: UiFacility): boolean {
  if (!facility || !facility.status_name) {
    return false;
  }
  return ['vacant', 'no_reservation'].indexOf(facility.status_name) > -1;
}

export function getFacilityBuildings(facilities: UiFacility[]): string[] {
  if (!facilities) {
    return [];
  }

  const bNames = facilities.reduce((acc: string[], fs: UiFacility): string[] => {
    const { features } = fs;
    if (!features) {
      return acc;
    }

    const bName = features.building;
    if (!bName) {
      return acc;
    }

    if (acc.indexOf(bName) > -1) {
      return acc;
    }

    acc.push(bName);
    return acc;
  }, []);

  return bNames.sort(sortByLocale);
}

export function getFacilityFloors(facilities: UiFacility[]): string[] {
  if (!facilities) {
    return [];
  }

  const fNames = facilities.reduce((acc: string[], fs: UiFacility): string[] => {
    const { features } = fs;
    if (!features || !features.floor) {
      return acc;
    }

    if (acc.indexOf(features.floor) > -1) {
      return acc;
    }

    acc.push(features.floor);
    return acc;
  }, []);

  return fNames.sort(sortByLocale);
}

export function makeFacilitySearchQueryFromUrlQuery(
  urlQuery: FacilityUrlQuery,
): FacilitySearchQuery {
  return Object.keys(urlQuery).reduce(
    (query, featureKey: keyof FacilityFeatures) => {
      // Validation
      if (FACILITY_FEATURES.indexOf(featureKey) === -1) {
        return query;
      }

      const feature = urlQuery[featureKey];
      if (!feature) {
        return query;
      }

      const queryValues = Array.isArray(feature) ? feature : [feature];
      if (queryValues.length === 0) {
        return query;
      }

      const newQuery = {} as FacilitySearchQuery;
      newQuery[featureKey] = queryValues;
      query = { ...query, ...newQuery };

      return query;
    },
    {} as FacilitySearchQuery,
  );
}

/**
 * @name removeEndedFacilityEvent
 *
 * @desc remove ended facility event from facilities
 * @param {UiFacility[]} facilities The array of UI facility
 *
 * @return {UiFacility[]]} returns the array of UI facility without ended events
 */
export function removeEndedFacilityEvent(facilities: UiFacility[]): UiFacility[] {
  const now = new Date();

  return facilities.map((facility) => {
    if (!facility.event || !facility.event.end_time) {
      return facility;
    }
    if (facility.event.end_time >= now) {
      return facility;
    }
    delete facility.event;
    return facility;
  });
}
