import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { State, Mutation, namespace } from 'vuex-class';
import { Message } from '@/store/flashMessage';
import OverlayModal from '@/components/templates/OverlayModal.vue';
import { availableLanguages, updateLanguage } from '@/language';
import Dropdown, { DropdownItem } from '@/components/molecules/Dropdown.vue';
import VueButton from '@/components/atoms/Button.vue';
import UserSettingBasic from '@/components/organisms/UserSettingBasic.vue';
import SuccessIcon from '@/assets/images/success.svg';
import UserHelper from '@/helpers/user';
import { UserPreference } from '@/api/models/UserPreference';
import logger from '@/logger';
import { UserDetails , TenantDetails } from '@/store/types/modules';
import UserNotificationSettings from '@/components/organisms/UserNotificationSettings.vue';
import ImIntegrationsHelper from '@/helpers/imIntegration';
import { UiImIntegration } from '@/models/imIntegration';
import { displayError, displaySuccess } from '@/helpers/message';


const userSettings = namespace('userSettings');
const user = namespace('user');

interface UpdateEvent extends Event {
  index: number;
}

interface AddressUpdateEvent extends UpdateEvent {
  $event: string;
}

interface ActivenessUpdateEvent extends UpdateEvent {
  $event: boolean;
}

@Component({
  components: {
    OverlayModal,
    Dropdown,
    SuccessIcon,
    UserSettingBasic,
    VueButton,
    UserNotificationSettings,
  },
})
export default class UserSettings extends Vue {
  @Prop({ type: String, required: true }) private header!: string;

  @Mutation('addMessage') addFlashMessage!: (m: Message) => void;

  @Mutation('resetMessages') resetFlashMessages!: () => void;

  @user.Mutation('setUserDetails') setUserDetails!: (u: UserDetails) => void;

  @userSettings.Mutation('setIsShown') userSettingsSetIsShown!: (isShown: boolean) => void;

  @State('user') user!: UserDetails;

  @State('tenant') tenant!: TenantDetails;

  selectedLang = 'en';

  selectedTimezone = 'Asia/Tokyo';

  currentPreference: UserPreference | null = null;

  currentImIntegrations: UiImIntegration[] = [];

  selectedImIntegrations: UiImIntegration[] = [];

  anyNotificationSettingChanged = false;

  loadingImIntegrations = false;

  get langItems(): DropdownItem[] {
    return Object.keys(this.availableLanguages).map(langKey => {
      const langName = this.availableLanguages[langKey];
      return { itemKey: langKey, name: langName };
    });
  }

  get availableLanguages(): { [language: string]: string } {
    return availableLanguages;
  }

  get basicSettingsChanged(): boolean {
    return (
      this.selectedLang !== this.currentLanguage || this.selectedTimezone !== this.currentTimezone
    );
  }

  get currentLanguage(): string {
    return this.user.accountLanguage;
  }

  get currentTimezone(): string {
    return this.user.accountTimezone;
  }

  get anySettingChanged(): boolean {
    return this.basicSettingsChanged || this.anyNotificationSettingChanged;
  }

  get availableTimezones(): DropdownItem[]{
    return [
      { itemKey: 'Asia/Tokyo', name: this.$gettext('Asia/Tokyo') },
      { itemKey: 'Asia/Taipei', name: this.$gettext('Asia/Taipei') },
      { itemKey: 'Asia/Singapore', name: this.$gettext('Asia/Singapore') },
    ];
  }

  get currentTenantID(): string {
    return this.tenant.current.id;
  }

  async fetchSettings(): Promise<void> {
    this.selectedLang = this.user.accountLanguage;
    this.selectedTimezone = this.user.accountTimezone;
    try{
      this.currentImIntegrations = await new ImIntegrationsHelper(this).fetchImIntegrations(this.currentTenantID);
      this.selectedImIntegrations = this.currentImIntegrations.map(imIntegration => ({ ...imIntegration, }));
    } catch (e) {
      logger.error('Failed to fetch settings', e);
      displayError(
        this.$gettext('We could not get your notification preferences. Please try reloading this page.'),
      );
    }
  }

  async created(): Promise<void> {
    this.anyNotificationSettingChanged = false;
    this.fetchSettings();
  }

  async submitBasicSettingsChange(): Promise<boolean> {
    const newPreference = {
      userPreference: {
        id: this.user.accountId,
        timezone: this.selectedTimezone,
        language: this.selectedLang,
        updated: new Date(),
      },
    };
    const newUserDetail = { ...this.user };
    newUserDetail.accountLanguage = this.selectedLang;
    newUserDetail.accountTimezone = this.selectedTimezone;
    try {
      await new UserHelper(this).postUserPreferences(newPreference);
      this.setUserDetails(newUserDetail);
      updateLanguage(this.selectedLang); // still update local storage for language detection before user logins
      Vue.config.language = this.selectedLang;
      logger.info(
        `Successfully updated user with id ${this.user.accountId}'s general preferences.`,
      );
    } catch (e) {
      logger.error('Failed to post user settings', e);
      return false;
    }
    return true;
  }

  async submitNotificationSettingsChange(): Promise<boolean> {
    const promises: Promise<void | null>[] = [];
    for (let i = 0; i < this.selectedImIntegrations.length; i += 1) {
      if (
        this.currentImIntegrations[i].active !== this.selectedImIntegrations[i].active ||
        this.currentImIntegrations[i].im_user_id !== this.selectedImIntegrations[i].im_user_id
      ) {
        promises.push(
          new ImIntegrationsHelper(this).createImIntegration(
            this.currentTenantID,
            this.selectedImIntegrations[i].im_integration_id,
            this.selectedImIntegrations[i] as UiImIntegration,
          ),
        );
      }
    }
    try {
      await Promise.all(promises);
    } catch (e) {
      logger.error(
        `Failed to update user with id ${this.user.accountId}'s notification settings: ${e}`,
      );
      return false;
    }
    logger.info(
      `Successfully updated user with id ${this.user.accountId}'s notification settings.`,
    );
    return true;
  }

  async submitForm(): Promise<void> {
    if (this.anySettingChanged) {
      let success = true;
      if (this.basicSettingsChanged) {
        success = success && await this.submitBasicSettingsChange();
      }

      if (this.anyNotificationSettingChanged) {
        success = success && await this.submitNotificationSettingsChange();
        this.anyNotificationSettingChanged = false;
      }

      if (success) {
        displaySuccess(this.$gettext('Your settings have been updated.'));
      } else {
        displayError(this.$gettext('We are sorry but we could not update your settings.'));
      }

      setTimeout(() => this.resetFlashMessages(), 3000);
    }
    this.userSettingsSetIsShown(false);
  }

  handleNotificationAddressUpdate(event: AddressUpdateEvent): void {
    const { index, $event: im_user_id } = event;
    this.selectedImIntegrations[index].im_user_id = im_user_id;
    this.updateNotificationSettingChanged();
  }

  handleNotificationActiveUpdate(event: ActivenessUpdateEvent): void {
    const { index, $event: active } = event;
    this.selectedImIntegrations[index].active = active;
    this.updateNotificationSettingChanged();
  }

  updateNotificationSettingChanged(): void {
    this.anyNotificationSettingChanged = this.currentImIntegrations.reduce(
      (acc, cur, idx) =>
        acc ||
        cur.active !== this.selectedImIntegrations[idx].active ||
        cur.im_user_id !== this.selectedImIntegrations[idx].im_user_id,
      false,
    );
  }

  reset(): void {
    this.selectedLang = this.currentLanguage;
    this.selectedTimezone = this.currentTimezone;
    this.selectedImIntegrations = this.currentImIntegrations.map(imIntegration => ({
      ...imIntegration,
    }));
    this.anyNotificationSettingChanged = false;
  }
}
