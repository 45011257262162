var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Form__group"},[_c('label',{staticClass:"Form__label"},[_vm._v(" "+_vm._s(_vm.translatedTitle)+" ")]),_c('span',{staticClass:"Form__description",attrs:{"translate-params":{ titleLowerCase: _vm.titleLowerCase }}},[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c('div',{staticClass:"Form__contents"},[_c('div',{staticClass:"Form__dropdown-wrapper"},[_c('div',{staticClass:"Form__dropdown-choice"},[_vm._v(_vm._s(_vm.dropdownItems.find(function (item) { return item.itemKey === _vm.selected; }).name))]),_c('dropdown',{attrs:{"id":("js-" + _vm.titleLowerCase + "-dropdown"),"enable-tail":false,"position":"down","align":"start","items":_vm.dropdownItems,"short":""},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('div',{staticClass:"Form__dropdown-arrow"})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var closeDropdown = ref.closeDropdown;
return [_c('div',[_c('a',{on:{"click":function($event){$event.preventDefault();return (function () {
                  _vm.$emit('input', item.itemKey)
                  closeDropdown();
                })($event)},"touchstart":function($event){$event.preventDefault();return (function () {
                  _vm.$emit('input', item.itemKey)
                  closeDropdown();
                })($event)}}},[_c('span',{staticClass:"Form__dropdown-item",class:{'Form__dropdown-item--selected': _vm.isSelected(item)}},[_vm._v(" "+_vm._s(item.name)+" ")]),(_vm.isSelected(item))?_c('success-icon',{staticClass:"Form__success-icon"}):_vm._e()],1)])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }