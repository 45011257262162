import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { State, Mutation, namespace } from 'vuex-class';
import { ToggleButton } from 'vue-js-toggle-button';
import { cloneDeep } from 'lodash';
import { Base64 } from 'js-base64';
import { Message } from '@/store/flashMessage';
import OverlayModal from '@/components/templates/OverlayModal.vue';
import VueButton from '@/components/atoms/Button.vue';
import VueRadio from '@/components/atoms/Radio.vue';
import BuildingsCheckboxGroup from '@/components/molecules/BuildingsCheckboxGroup.vue';
import SuccessIcon from '@/assets/images/success.svg';
import Loading from '@/components/atoms/Loading.vue';
import { SettingsQuery, SignageSettings as SignageSettingsState, SignageUrlQuery, FloorSettings, TenantDetails } from '@/store/types/modules';
import { UiFacility } from '@/models/facility';
import { makeFacilitySearchQueryFromUrlQuery } from '@/formatters/facility';
import TenantHelper from '@/helpers/tenant';
import FacilityHelper from '@/helpers/facility';

const signageSettingsState = namespace('signageSettings');

const view = namespace('view');

@Component({
  components: { OverlayModal, SuccessIcon, VueButton, BuildingsCheckboxGroup, VueRadio, Loading, ToggleButton },
})
export default class SignageSettings extends Vue {
  @Prop({ type: String, required: true }) private header!: string;

  @Mutation('addMessage') addFlashMessage!: (m: Message) => void;

  @Mutation('resetMessages') resetFlashMessages!: () => void;

  @view.Mutation('setSettingsQuery') setSettingsQuery!: (v: SettingsQuery) => void;

  @signageSettingsState.Mutation('setIsShown') signageSettingsSetIsShown!: (isShown: boolean) => void;

  @signageSettingsState.Mutation('setBuildings') signageSettingsSetBuildings!: (buildings: string[]) => void;

  @signageSettingsState.Mutation('setFloors') signageSettingsSetFloors!: (params: FloorSettings[]) => void;

  @State('tenant') tenant!: TenantDetails;

  @view.State('settingsQuery') settingsQuery!: SettingsQuery;

  @signageSettingsState.State('selectedBuildings') selectedBuildings!: string[];

  @signageSettingsState.State('selectedFloors') selectedFloors!: FloorSettings[];

  @signageSettingsState.State('isShown') signageSettingsIsShown!: SignageSettingsState;

  defaultView: SignageUrlQuery = {
    buildings: [],
    floors: [],
    publicAccess: false,
    sort: 'name:asc',
    current: '',
  };

  currentView: SignageUrlQuery = {
    buildings: [],
    floors: [],
    publicAccess: false,
    sort: 'name:asc',
    current: '',
  };

  model: SignageUrlQuery = {
    buildings: [],
    floors: [],
    publicAccess: false,
    sort: 'name:asc',
    current: '',
  };

  toggle_button_style_props = {
    color: {
      checked: '#bde8ce',
      unchecked: '#c0c0c0',
    },
    switchColor: {
      checked: '#45cf7c',
      unchecked: 'white',
    },
    width: 36,
    height: 12,
    margin: -4,
  };

  isLoading = true;

  facilities: UiFacility[] = [];

  get tenantHelper(): TenantHelper {
    return new TenantHelper(this);
  }

  get facilityHelper(): FacilityHelper {
    return new FacilityHelper(this);
  }

  get buildings(): string[] {
    return this.facilityHelper.buildings(this.facilities);
  }

  get currentBuildingsSorted(): string[] {
    const currentBuildings: string[] = cloneDeep(this.currentView.buildings);
    return currentBuildings.sort();
  }

  get currentFloorsSorted(): FloorSettings[] {
    const currentFloors: FloorSettings[] = cloneDeep(this.currentView.floors);
    return currentFloors.sort((a, b) => (a.building > b.building) ? 1 : -1);
  }

  get hasSettingChanged(): boolean {
    if (!this.currentView) return false;
    return this.selectedBuildings.length !== this.currentView.buildings.length
      || this.selectedFloors.length !== this.currentView.floors.length
      || this.model.sort !== this.currentView.sort
      || this.model.publicAccess !== this.currentView.publicAccess
      || !this.isBuildingsListEqual
      || !this.isFloorsListEqual;
  }

  get isBuildingsListEqual(): boolean {
    return this.selectedBuildings.every((building, i) => { 
      return this.currentView && building === this.currentBuildingsSorted[i];
    });
  }

  get isFloorsListEqual(): boolean {
    return this.selectedFloors.every((item, i) => { 
      if (!this.currentFloorsSorted[i]) {
        return false;
      }

      return (item.floors.length === this.currentFloorsSorted[i].floors.length) 
        && item.floors.sort().every((floor, y) => { 
          return this.currentFloorsSorted[i] 
            && floor === this.currentFloorsSorted[i].floors.sort()[y];
        });
    });
  }

  async created(): Promise<void> {
    this.isLoading = true;
    await this.init();
    this.isLoading = false;
  }

  @Watch('tenant.current.id')
  async init(): Promise<void> {
    const fs = await this.fetchFacilities();
    this.facilities = fs ? [...fs] : [];
    await this.setDefaultView();
    this.setCurrentView();
    this.signageSettingsSetBuildings(this.currentView.buildings);
    this.signageSettingsSetFloors(this.currentView.floors);
  }

  async setDefaultView(): Promise<void> {
    this.defaultView.buildings = this.buildings;
    const floors: FloorSettings[] = [];
    await Promise.all(this.buildings.map(async (building) => {
      const params: FloorSettings = {
        building,
        floors: await this.getFloorsByBuilding(building)
      };
      floors.push(params);
    }));
    this.defaultView.floors = cloneDeep(floors);
  }

  setCurrentView(): void {
    if (this.settingsQuery.facilitiesSignage) {
      this.currentView = cloneDeep(this.settingsQuery.facilitiesSignage);
    } else {
      this.currentView = cloneDeep(this.defaultView);
      this.setSettingsQuery({ facilitiesSignage: this.defaultView });
    }
    this.model = cloneDeep(this.currentView);
  }

  async getFloorsByBuilding(building: string): Promise<string[]> {
    const tenantId = this.tenantHelper.currentTenantId();
    const query = makeFacilitySearchQueryFromUrlQuery({
      building: [building],
    });
    const fs = await this.facilityHelper.fetchFacilities(tenantId, query, 'name:asc');
    const facilities = fs ? [...fs] : [];
    return this.facilityHelper.floors(facilities);
  }

  async fetchFacilities(): Promise<UiFacility[] | null> {
    const tenantId = this.tenantHelper.currentTenantId();
    if (!(await this.tenantHelper.validTenantId(tenantId))) {
      return null;
    }
    return this.facilityHelper.fetchFacilities(tenantId);
  }

  sortHandler(params: string) : void {
    this.model.sort = params;
  }

  async submitForm(): Promise<void> {
    this.model.buildings = cloneDeep(this.selectedBuildings);
    this.model.floors = cloneDeep(this.selectedFloors);

    if (this.settingsQuery.facilitiesSignage && this.model.buildings.includes(this.settingsQuery.facilitiesSignage.current)) {
      this.model.current = this.settingsQuery.facilitiesSignage.current;
    } else {
      const [firstBuilding] = this.selectedBuildings;
      this.model.current = firstBuilding;
    }

    const encodedQuery = Base64.encode(JSON.stringify(this.model));
    this.setSettingsQuery({ facilitiesSignage: this.model });
    this.setCurrentView();

    this.$router.push({
      name: 'facilitiesSignage',
      query: { settings: encodedQuery },
    });

    this.closeHandler();
  }

  reset(): void {
    this.model.sort = this.currentView.sort;
    this.model.publicAccess = this.currentView.publicAccess;
    this.signageSettingsSetBuildings(this.currentView.buildings);
    this.signageSettingsSetFloors(this.currentView.floors);
  }

  closeHandler(): void {
    this.signageSettingsSetIsShown(false);
    this.reset();
  }
}
