/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExtendEventRequest
 */
export interface ExtendEventRequest {
    /**
     * 
     * @type {number}
     * @memberof ExtendEventRequest
     */
    duration_in_min: number;
}

export function ExtendEventRequestFromJSON(json: any): ExtendEventRequest {
    return ExtendEventRequestFromJSONTyped(json, false);
}

export function ExtendEventRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExtendEventRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'duration_in_min': json['duration_in_min'],
    };
}

export function ExtendEventRequestToJSON(value?: ExtendEventRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'duration_in_min': value.duration_in_min,
    };
}


