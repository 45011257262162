import Vue from 'vue';
import { Module } from 'vuex';
import { RootState } from '@/store/types/rootState';
import { BookedFacility } from '@/store/types/modules';

import { UiFacility } from '@/models/facility';

const bookedFacilityModule: Module<BookedFacility, RootState> = {
  state: {
    facilities: {},
  },
  mutations: {
    setBookedFacilities(
      state: BookedFacility,
      payload: { tenantId: string; facilities: UiFacility[] },
    ): void {
      const initFacilities = { ...state.facilities, ...{ tenantId: payload.facilities } };
      Vue.set(state.facilities, payload.tenantId, initFacilities);
    },
    addBookedFacility(
      state: BookedFacility,
      payload: { tenantId: string; facility: UiFacility },
    ): void {
      if (!payload.facility) {
        return;
      }

      if (!state.facilities[payload.tenantId]) {
        Vue.set(state.facilities, payload.tenantId, [payload.facility]);
        return;
      }

      const newFacilities = [...state.facilities[payload.tenantId], ...[payload.facility]];
      Vue.set(state.facilities, payload.tenantId, newFacilities);
    },
    deleteBookedFacility(
      state: BookedFacility,
      payload: { tenantId: string; facilityId: string },
    ): void {
      if (!payload.facilityId) {
        return;
      }

      if (!state.facilities[payload.tenantId]) {
        Vue.set(state.facilities, payload.tenantId, []);
        return;
      }

      const newFacilities = state.facilities[payload.tenantId].filter(f => {
        return f.facility_id !== payload.facilityId;
      });
      Vue.set(state.facilities, payload.tenantId, newFacilities);
    },
  },
};

export default bookedFacilityModule;
