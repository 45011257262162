/**
 * JSON Web Token utilities
 *
 */

import jwtDecode from 'jwt-decode';

import logger from '@/logger';

export interface IdToken {
  exp?: number;
  iat?: number;
  iss?: string;
  sub?: string;
}

export interface HashTokens {
  idToken?: string,
  accessToken?: string,
  accessTokenExpiresIn?: number,
  state?: string,
}

export const decodeIdToken = <T extends IdToken>(idTokenRaw: string): T => jwtDecode<T>(idTokenRaw);

export function extractTokensFromHash(hash: string): HashTokens {
  const hashParameters = hash
    .substr(1)
    .split('&')
    .map(p => p.split('=', 2));

  const idToken = hashParameters
    .find(s => s[0] === 'id_token')
    ?.[1];

  const accessToken = hashParameters
    .find(s => s[0] === 'access_token')
    ?.[1];

  const expiresInStr = hashParameters
    .find(s => s[0] === 'expires_in')
    ?.[1] || '';
  const accessTokenExpiresIn = +expiresInStr;

  const state = hashParameters
    .find(s => s[0] === 'state')
    ?.[1];

  return {
    idToken,
    accessToken,
    accessTokenExpiresIn,
    state,
  };
}

export const isExpiredTime = (expiresAtSec: number): boolean => {
  const nowUnitxTimeSec = Math.round(new Date().getTime() / 1000);
  return nowUnitxTimeSec >= expiresAtSec;
};

/**
 * validateIdToken checks if the given ID token whose format is JWT is valid.
 *
 * @param {string} idToken - ID token whose format is JWT
 * @return {boolean} The flag to represent if the given ID token is valid
 */
export function validateIdToken(idToken: string): boolean {
  if (!idToken) {
    logger.info('ID token is empty');
    return false;
  }

  const token = decodeIdToken<IdToken>(idToken);

  if (!token.exp || isExpiredTime(token.exp)) {
    logger.warn('ID token is invalid', {
      expiresAt: token.exp,
      issuer: token.iss,
      subject: token.sub,
    });
    return false;
  }

  logger.info('ID token is valid', {
    expiresAt: token.exp,
    issuer: token.iss,
    subject: token.sub,
  });

  return true;
}
