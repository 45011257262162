var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{class:['Dashboard', ("Dashboard--" + _vm.mode)],attrs:{"id":"user-dashboard"}},[_c('router-view',{staticClass:"Dashboard__header",attrs:{"name":"header"}}),_c('header',{staticClass:"Dashboard__header"},[_c('navibar',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('dropdown',{class:_vm.isPublicDisplayView ? 'fade-out' : 'fade-in',attrs:{"id":"js-menu-dropdown","enable-tail":true,"position":"down","align":"start","items":_vm.menuDropdownItems},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('label',{staticClass:"Dashboard__navi-button Dashboard__navi-button--left",attrs:{"id":"js-menu-button"}},[_c('booking-menu-icon',{staticClass:"Dashboard__navi-icon Dashboard__navi-icon--booking-menu",class:{
                  'Dashboard__navi-icon--active': _vm.isActiveMenu(_vm.menuLinkTable['bookingFacility']),
                }}),_c('signage-menu-icon',{staticClass:"Dashboard__navi-icon Dashboard__navi-icon--booking-menu",class:{
                  'Dashboard__navi-icon--active': _vm.isActiveMenu(
                    _vm.menuLinkTable['facilitiesSignage']
                  ),
                }}),(_vm.hasTabletViewPermission)?_c('signage-menu-icon',{staticClass:"Dashboard__navi-icon Dashboard__navi-icon--booking-menu",class:{
                  'Dashboard__navi-icon--active': _vm.isActiveMenu(
                    _vm.menuLinkTable['facilityDashboardLinks']
                  ),
                }}):_vm._e(),_c('down-arrow-icon')],1)]},proxy:true},{key:"item",fn:function(ref){
                var mItem = ref.item;
                var close = ref.closeDropdown;
return [_c('div',{staticClass:"Dashboard__navi-item js-menu-contents",class:_vm.isActiveMenu(_vm.menuLinkTable[mItem.itemKey]) ? 'Dashboard__navi-item--active' : '',attrs:{"id":"js-menu-contents"},on:{"click":close}},[_c('router-link',{attrs:{"id":("js-" + (_vm.kebabCase(mItem.itemKey)) + "-menu"),"to":_vm.menuLinkTable[mItem.itemKey].location,"active-class":"js-menu-active"}},[_c(_vm.menuIconComponent(mItem.itemKey),{tag:"component",staticClass:"Dashboard__navi-item-icon"}),_c('span',[_vm._v(_vm._s(_vm.menuLinkTable[mItem.itemKey].name))]),(_vm.isActiveMenu(_vm.menuLinkTable[mItem.itemKey]))?_c('success-icon',{staticClass:"Dashboard__success-icon"}):_vm._e()],1)],1)]}}])})]},proxy:true},{key:"center",fn:function(){return [_c('span',{staticClass:"Dashboard__date"},[_vm._v(_vm._s(_vm.date))]),_c('span',{staticClass:"Dashboard__time"},[_vm._v(_vm._s(_vm.time))])]},proxy:true},{key:"right",fn:function(){return [_c('dropdown',{class:_vm.isPublicDisplayView ? 'fade-out' : 'fade-in',attrs:{"id":"js-setting-dropdown","enable-tail":true,"position":"down","align":"end","is-side-menu":true,"items":_vm.settingDropdownItems,"drop-down-open-id":"js-setting-contents"},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('label',{staticClass:"Dashboard__navi-button Dashboard__navi-button--right",attrs:{"id":"js-setting-button"}},[_c('account-menu-icon',{staticClass:"Dashboard__navi-icon--account-menu"})],1)]},proxy:true},{key:"item",fn:function(ref){
                var rItem = ref.item;
                var close = ref.closeDropdown;
return [(rItem.itemKey === 'profile')?[_c('div',{staticClass:"Dashboard__profile"},[(_vm.$store.state.idp.profilePictureUrl)?_c('img',{staticClass:"Dashboard__profile-picture",attrs:{"src":_vm.$store.state.idp.profilePictureUrl}}):_c('ghost-icon',{staticClass:"Dashboard__profile-picture"}),_c('dl',{staticClass:"Dashboard__profile-contents"},[_c('dt',[_vm._v(" "+_vm._s(_vm.$store.state.idp.profileName || _vm.$gettext('Unknown Name'))+" ")]),_c('dd',[_vm._v(" "+_vm._s(_vm.$store.state.idp.profileEmail || _vm.$gettext('Unknown Email'))+" ")])])],1)]:_vm._e(),(rItem.itemKey === 'switch-current-tenant')?[_c('div',{staticClass:"Dashboard__navi-item"},[_c('dropdown',{attrs:{"position":"left","align":"start","is-fluid":true,"is-accordion":true,"enable-hover":true,"items":_vm.selectableTenants,"disabled":_vm.selectableTenants.length <= 1},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('dl',{staticClass:"Dashboard__current-tenant"},[_c('translate',{attrs:{"tag":"dt"}},[_vm._v("Your Organization")]),_c('dd',[_vm._v(_vm._s(_vm.tenantHelper.currentTenantName()))])],1)]},proxy:true},{key:"item",fn:function(ref){
                var tenantItem = ref.item;
return [_c('div',{staticClass:"Dashboard__navi-item"},[_c('button',{on:{"click":function($event){$event.preventDefault();return (function () {
                            _vm.tenantHelper.switchCurrentTenant(tenantItem.tenant);
                          })($event)},"touchstart":function($event){$event.preventDefault();return (function () {
                            _vm.tenantHelper.switchCurrentTenant(tenantItem.tenant);
                          })($event)}}},[_c('span',{class:{
                            'Dashboard__current-tenant--selected':
                              tenantItem.tenant.id === _vm.tenantHelper.currentTenantId(),
                          }},[_vm._v(" "+_vm._s(tenantItem.tenant.name)+" ")]),(tenantItem.tenant.id === _vm.tenantHelper.currentTenantId())?_c('success-icon',{staticClass:"Dashboard__success-icon"}):_vm._e()],1)])]}}],null,true)})],1)]:_vm._e(),(rItem.itemKey === 'user-settings')?[_c('div',{staticClass:"Dashboard__navi-item",attrs:{"id":"js-user-settings"},on:{"click":function () {
                    close();
                    _vm.userSettingsSetIsShown(true);
                  }}},[_c('a',[_c('translate',{key:("navi-item-word-" + (rItem.itemKey))},[_vm._v(" Account Settings ")])],1)])]:_vm._e(),(rItem.itemKey === 'signage-settings')?[_c('div',{staticClass:"Dashboard__navi-item",attrs:{"id":"js-signage-settings"},on:{"click":function () {
                    close();
                    _vm.signageSettingsSetIsShown(true);
                  }}},[_c('a',[_c('translate',{key:("navi-item-word-" + (rItem.itemKey))},[_vm._v(" Signage Settings ")])],1)])]:_vm._e(),(rItem.itemKey === 'go-to-admin')?[_c('div',{staticClass:"Dashboard__navi-item",on:{"click":close}},[_c('a',{attrs:{"href":_vm.adminURL}},[_c('external-link-icon'),_c('translate',{key:("navi-item-word-" + (rItem.itemKey))},[_vm._v("Go to Admin Console")])],1)])]:_vm._e(),(rItem.itemKey === 'switch-public-mode')?[_c('div',{staticClass:"Dashboard__navi-item",attrs:{"id":"js-switch-public-mode"},on:{"click":function () {
                    _vm.goPublicView(close);
                  }}},[_c('a',[_c('translate',{key:("navi-item-word-" + (rItem.itemKey))},[_vm._v(" Display Public Mode ")])],1)])]:_vm._e(),(rItem.itemKey === 'logout')?[_c('div',{staticClass:"Dashboard__navi-item",on:{"click":close}},[_c('router-link',{attrs:{"to":{ name: 'logout' }}},[_c('translate',{key:("navi-item-word-" + (rItem.itemKey))},[_vm._v("Logout")])],1)],1)]:_vm._e()]}}])})]},proxy:true}])})],1),_c('article',{staticClass:"Dashboard__contents"},[_c('router-view',{staticClass:"Dashboard__view",attrs:{"name":"view"}}),(_vm.userSettingsIsShown)?_c('user-settings',{attrs:{"header":_vm.$gettext('Account Settings')}}):_vm._e(),_c('fullscreen-confirmation',{attrs:{"show":_vm.fullscreenConfirmationIsShown},on:{"close":function () {
          _vm.fullscreenConfirmationIsShown = false;
        }}}),_c('signage-settings',{attrs:{"header":_vm.$gettext('Signage Settings')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }