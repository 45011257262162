var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"Dropdown",class:[
    _vm.enableTail ? 'Dropdown--tail' : '',
    _vm.position ? ("Dropdown--position-" + _vm.position) : 'Dropdown--position-down',
    _vm.align ? ("Dropdown--align-" + _vm.align) : 'Dropdown--align-start',
    _vm.isAccordion ? 'Dropdown--accordion' : '',
    _vm.isFluid ? 'Dropdown--fluid': '',
    _vm.short? 'Dropdown--short' : '' ]},[_c('button',{class:['Dropdown__button',
             {
               'Dropdown__button--opened': _vm.isOpened,
               'Dropdown__button--side-menu': _vm.isSideMenu,
               'Dropdown__button--accordion': _vm.isAccordion,
               'Dropdown__button--disabled': _vm.disabled,
             }],attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.clickHandler($event)},"touchstart":function($event){$event.preventDefault();return _vm.touchHandler($event)},"mouseenter":function($event){$event.preventDefault();return (function () {
        if (_vm.enableHover) { _vm.open(); }
      })($event)},"mouseleave":function($event){$event.preventDefault();return (function () {
        if (_vm.enableHover) { _vm.lazyClose(); }
      })($event)}}},[_vm._t("button")],2),_c('ul',{staticClass:"Dropdown__items",class:{
      'Dropdown__items--opened': _vm.isOpened,
      'Dropdown__items--side-menu': _vm.isSideMenu,
      'Dropdown__items--accordion': _vm.isAccordion,
      'Dropdown__items--short': _vm.short,
    },attrs:{"id":_vm.dropDownOpenId},on:{"mouseenter":function($event){$event.preventDefault();return (function () {
        if (_vm.enableHover) { _vm.keepOpen(); }
      })($event)},"mouseleave":function($event){$event.preventDefault();return (function () {
        if (_vm.enableHover) { _vm.close(); }
      })($event)}}},_vm._l((_vm.items),function(item,index){return _c('li',{key:("dropdown-item-" + index),class:[item.itemKey === 'stake' ? 'Dropdown__stake' : 'Dropdown__item']},[_vm._t("item",null,{"index":index,"item":item,"openDropdown":_vm.open,"closeDropdown":_vm.close,"togglenDropdown":_vm.toggle})],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }