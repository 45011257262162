import Vue from 'vue';
import GetTextPlugin from 'vue-gettext';

import translation from './i18n/translation.json';

export const availableLanguages: { [language: string]: string } = {
  en: 'English',
  ja: '日本語',
};

export const sortByLocale = (a: string, b: string): number =>
  a.localeCompare(b, undefined, { numeric: true });

/**
 * @name isLanguageAvailable
 * @desc Checks whether the language is available
 *
 * @param {string | null} langage
 *
 * @return {boolean}
 */
function isLanguageAvailable(langage: string | null): boolean {
  if (!langage) {
    return false;
  }

  const languages = Object.keys(availableLanguages);

  return languages.some((l): boolean => {
    const re = new RegExp(`^${l}`);
    return re.test(l);
  });
}

/**
 * @name getLanguage
 * @desc Gets the language for the app used during bootstrapping the app
 *
 * 1. Checks if the language saved and available, if so returns the saved langauge
 * 2. If not, checks if the brower's default language is available
 * 3. Otherwise, defaults to English
 *
 * @param {[locale: string]: any} locales
 *
 * @return {string} returns the locale
 */
export function getLanguage(): string {
  let localStorageLanguage = window.localStorage.getItem('language');
  localStorageLanguage = localStorageLanguage || '';
  const browserLanguage = window.navigator.language.replace(/-/g, '_');
  if (isLanguageAvailable(localStorageLanguage)) {
    return localStorageLanguage;
  }

  if (isLanguageAvailable(browserLanguage)) {
    return browserLanguage;
  }

  return 'en';
}

/**
 * @name updateLanguage
 * @desc Update the language for the app
 *
 * @param {string} language
 */
export function updateLanguage(language: string): void {
  window.localStorage.setItem('language', language);
}

Vue.use(GetTextPlugin, {
  availableLanguages,
  silent: true,
  defaultLanguage: getLanguage(),
  translations: translation,
});
