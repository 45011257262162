import { Module } from 'vuex';
import { RootState } from '@/store/types/rootState';
import { SafariPatch } from '@/store/types/modules';

const safariPatchModule: Module<SafariPatch, RootState> = {
  state: {
    idTokenForPatch: '',
    intentionallyLoggingIn: false
  },
  mutations: {
    setIDTokenForPatch(state: SafariPatch, idToken: string): void {
      state.idTokenForPatch = idToken;
    },
    setIntentionallyLoggingIn(state: SafariPatch, intentionallyLoggingIn: boolean): void {
      state.intentionallyLoggingIn = intentionallyLoggingIn;
    },
    clearSafariPatch(state: SafariPatch): void {
      state.idTokenForPatch = '';
      state.intentionallyLoggingIn = false;
    }
  },
};

export default safariPatchModule;
