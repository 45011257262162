import store from '@/store';
import { validateIdToken } from '@/oidc/jwt';
import logger from '@/logger';
import { Idp } from '@/store/types/modules';


/**
 * redirectToLogin Redirects to login page.
 *
 * @param {boolean} withUrlParam - The flag reporesents
 *  if login path has current URL as URL search query
 */
export function redirectToLogin(withUrlParam = true): void {
  window.location.href = withUrlParam
    ? `/login?url=${encodeURIComponent(window.location.href)}`
    : '/login';
}

/**
 * resetAuthToken clears auth token in localStorage to logout
 *
 * @param {boolean} withUrlParam - The flag represents
 *  if login path has current URL as URL search query
 */
export function resetAuth(withUrlParam = true): void {
  store.commit('setAuthToken', {});
  store.commit('setIdp', {});
  store.commit('clearSafariPatch');
  logger.info('Resetting token');
  redirectToLogin(withUrlParam);
}

export function isIDPValid(idp: Idp): boolean {
  return !! ( idp.idpName
           && idp.idToken
  );
}

export function isLoggedIn(): boolean {
  return !! store.state.authToken
         && validateIdToken(store.state.authToken.token);
}
