/**
 * Facility component helper in components
 *
 * @desc helps do CRUD in components for faciliies
 */
import { Store } from 'vuex';

import logger from '@/logger';
import apiConfiguration from '@/helpers/config';
import { FacilityApi } from '@/api/apis/FacilityApi';
import { AuthToken , Idp , TenantDetails } from '@/store/types/modules';
import { FlashMessage } from '@/store/flashMessage';
import { authMiddleware } from '@/helpers/authToken';
import TenantHelper from '@/helpers/tenant';
import { UiFacility, FacilitySearchQuery } from '@/models/facility';
import { Event } from '@/api';
import {
  UiFacilitySortCompareFunc,
  isAvailableFacility,
  getFacilityBuildings,
  getFacilityFloors,
  facilityStatusName,
  sortByFacilityName,
  sortByFacilityFeature,
  filterByQueryParams,
} from '@/formatters/facility';

interface FacilityHelperDeps {
  $gettext: (msgid: string) => string;
  $store: Store<{
    authToken: AuthToken;
    flashMessage: FlashMessage;
    idp: Idp;
    tenant: TenantDetails;
  }>;
}

interface FacilityHelperAssists {
  tenant: TenantHelper;
}

export interface FloorPage {
  floorName: string,
  subPageNumber: number,
  facilities: UiFacility[],
}

const SORT_FUNCS: { [prop: string]: (object: string) => UiFacilitySortCompareFunc } = {
  name: sortByFacilityName,
  building: sortByFacilityFeature('building'), // TODO: Add UI for building order
  capacity: sortByFacilityFeature('capacity'), // TODO: Add UI for capacity order
  floor: sortByFacilityFeature('floor'), // TODO: Add UI for floor order
};

export function floor(facility: UiFacility): string {
  const { features } = facility;
  return features && features.floor || '';
}

function groupInFloor(collectedFacilitiesByFloor: Map<string, UiFacility[]>, facility: UiFacility): Map<string, UiFacility[]> {
  const floorName = floor(facility);
  const floorFacilities = collectedFacilitiesByFloor.get(floorName) || [];
  floorFacilities.push(facility);
  collectedFacilitiesByFloor.set(floorName, floorFacilities);
  return collectedFacilitiesByFloor;
}

function divideByFloors(facilities: UiFacility[]): [string, UiFacility[]][] {
  const facilitiesPerFloor  = facilities.reduce(groupInFloor, new Map<string, UiFacility[]>());
  return Array.from(facilitiesPerFloor);
}

function subDivideFloor(
  fullFloor: [string, UiFacility[]],
  pageSize: number,
): Array<FloorPage> {
  const floorName = fullFloor[0];
  const paginated: UiFacility[][] = [];
  for (let i = 0, j = fullFloor[1].length; i < j; i += pageSize) {
    paginated.push(fullFloor[1].slice(i, i + pageSize));
  }
  return paginated.map((facilities, subPageNumber) => {
    return {
      floorName,
      subPageNumber,
      facilities,
    };
  });
}

export function divideByFloorsAndPaginate(
  facilities: UiFacility[],
  pageSize: number,
): Array<FloorPage> {
  return divideByFloors(facilities)
    .flatMap(divided => subDivideFloor(divided, pageSize));
}

export function isCustomFacility(facility: UiFacility): boolean {
  return !facility.calendar_integration_id || !facility.calendar_id;
}

export default class FacilityHelper {
  vue: FacilityHelperDeps;

  helper: FacilityHelperAssists;

  constructor(deps: FacilityHelperDeps) {
    this.vue = deps;

    this.helper = {
      tenant: new TenantHelper(deps),
    };
  }

  facilityName(facility: UiFacility): string {
    const facilityName = facility.facility_name;
    const unknown = this.vue.$gettext('Unknown Facility');
    if (!facilityName) {
      return unknown;
    }
    return facilityName;
  }

  organizer(facility: UiFacility): string {
    const { event } = facility;

    if (!event?.organizer) {
      return this.vue.$gettext('Unknown Organizer');
    }

    return event.organizer?.display_name || event.organizer.email;
  }

  building(facility: UiFacility): string {
    const { features } = facility;
    const unknown = this.vue.$gettext('Unknown Building');
    if (!features || !features.building) {
      return unknown;
    }
    return features.building;
  }

  formatFloor(floorName: string): string {
    return floorName === ''
      ? this.vue.$gettext('Unknown Floor')
      : floorName + this.vue.$gettext('F');
  }

  getFormattedFloor(facility: UiFacility): string {
    return this.formatFloor(floor(facility));
  }

  capacity(facility: UiFacility): string {
    const { features } = facility;
    const unknown = this.vue.$gettext('Unknown Capacity');
    if (!features || !features.capacity) {
      return unknown;
    }
    return features.capacity;
  }

  attendeesCount(facility: UiFacility): string {
    const { event } = facility;
    const unknown = this.vue.$gettext('Unknown Attendees');
    if (!event || !event.attendees_count) {
      return unknown;
    }
    return event.attendees_count.toString();
  }

  isAvailable(facility: UiFacility): boolean {
    return isAvailableFacility(facility);
  }

  buildings(facilities: UiFacility[]): string[] {
    return getFacilityBuildings(facilities);
  }

  floors(facilities: UiFacility[]): string[] {
    return getFacilityFloors(facilities);
  }

  async fetchFacilities(
    tenantId: string,
    query?: FacilitySearchQuery,
    sort?: string,
  ): Promise<UiFacility[]> {
    const facilityApi = new FacilityApi(apiConfiguration)
      .withMiddleware(authMiddleware);

    logger.info('Fetching tenant facilities...');

    const facilities = await facilityApi
      .getTenantFacilities({ tenantId })
      .then(f => f.filter(filterByQueryParams(query || {})));
    logger.info('Tenant facilities have been fetched and filtered', { facilities });

    let sortFunc;
    const sortParts = sort ? sort.split(':', 2) : [];
    if (sortParts.length === 2) {
      const sortKey = sortParts[0];
      let sortOrder = sortParts[1];
      if (['asc', 'desc'].indexOf(sortOrder) === -1) {
        sortOrder = 'asc';
      }

      if (SORT_FUNCS[sortKey]) {
        sortFunc = SORT_FUNCS[sortKey](sortOrder);
        logger.info(`Tenant facilities are sorted by ${sortKey} in ${sortOrder} order`);
      }
    }

    let newUiFacilities = ([...facilities] as UiFacility[]).sort(sortFunc);

    logger.info('Fetching tenant facilities status...');

    const facilitiesStatus = await facilityApi.getTenantFacilitiesStatus({ tenantId });

    logger.info('Tenant facilities status has been fetched');

    newUiFacilities = newUiFacilities.map(uiFs => {
      const fsIndex = facilitiesStatus.status.findIndex(fs => uiFs.facility_id === fs.facility_id);
      let newUiFs = uiFs;
      if (fsIndex > -1) {
        newUiFs = { ...uiFs, ...facilitiesStatus.status[fsIndex] };
      }
      const statusName = facilityStatusName(newUiFs);
      return { ...newUiFs, ...{ status_name: statusName } };
    });

    return newUiFacilities;
  }

  async fetchFacility(
    tenantId: string,
    facilityId: string
  ): Promise<UiFacility> {
    const facilityApi = new FacilityApi(apiConfiguration)
      .withMiddleware(authMiddleware);

    logger.info('Fetching tenant facilities...');

    const facility = await facilityApi.getTenantFacility({ tenantId, facilityId });
    logger.info('Tenant facility has been fetched', { facility });

    logger.info('Fetching tenant facilities status...');

    const facilityStatus = await facilityApi.getTenantFacilityStatus({ tenantId, facilityId });

    logger.info('Tenant facility status has been fetched');

    const statusName = facilityStatusName(facilityStatus.status);

    return { ...facility, ...facilityStatus.status, ...{ status_name: statusName } };
  }

  async fetchFacilityEvents(
    tenantId: string,
    facilityId: string,
    startTime: Date,
    endTime: Date
  ): Promise<Event[]> {
    const facilityApi = new FacilityApi(apiConfiguration)
      .withMiddleware(authMiddleware);

    logger.info('Fetching facility events...');

    const res = await facilityApi.getTenantEvents({ tenantId, startTime, endTime, facilityId });
    
    const [facilityEvent] = res; 
    const { events } = facilityEvent;

    logger.info('Facility events has been fetched', { events });

    return events;
  }
}
