import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { cloneDeep } from 'lodash';
import VueDialog from '@/components/molecules/Dialog.vue';
import { SettingsQuery, SignageSettings, FloorSettings, SignageUrlQuery } from '@/store/types/modules';
import VueButton from '@/components/atoms/Button.vue';
import VueCheckbox from '@/components/atoms/Checkbox.vue';
import Loading from '@/components/atoms/Loading.vue';
import { UiFacility } from '@/models/facility';

const signageSettings = namespace('signageSettings');
const view = namespace('view');

@Component({
  components: { VueDialog, VueButton, VueCheckbox, Loading },
})
export default class BuildingAreas extends Vue {
  @Prop({ type: String, required: true }) private building!: string;

  @Prop({ type: Object, required: true }) private defaultView!: SignageUrlQuery;

  @view.State('settingsQuery') viewSettingsQuery!: SettingsQuery;

  @signageSettings.State('isAreaShown') isAreaShown!: SignageSettings;

  @signageSettings.State('selectedFloors') selectedFloors!: FloorSettings[];

  @signageSettings.Mutation('setIsAreaShown') setIsAreaShown!: (params: boolean) => void;

  @signageSettings.Mutation('setFloorsByBuilding') setFloorsByBuilding!: (params: FloorSettings) => void;

  @signageSettings.Mutation('setFloors') setFloors!: (params: FloorSettings[]) => void;
  
  @signageSettings.Mutation('removeBuilding') removeBuilding!: (params: string) => void;

  facilities: UiFacility[] = [];

  model: string[] = [];

  viewBuildings: string[] = [];

  viewFloors: FloorSettings[] = [];

  isLoading = true;

  get floors(): string[] {
    const index = this.defaultView.floors.findIndex(f => f.building === this.building);
    if (index !== -1) {
      return this.defaultView.floors[index].floors;
    }
    return [];
  }

  get isAllFloorsChecked(): boolean {
    return this.floors?.length === this.model.length;
  }

  get modelSet(): Set<string> {
    return new Set(this.model);
  }

  @Watch('selectedFloors')
  setModelFromStore(): void {
    this.model = [];
    const index = this.selectedFloors.findIndex(f => f.building === this.building);
    if (index !== -1) {
      this.model = cloneDeep(this.selectedFloors[index].floors);
    }
  }

  async created(): Promise<void> {
    this.isLoading = true;
    this.init();
    this.isLoading = false;
  }

  init(): void {
    if (this.viewSettingsQuery.facilitiesSignage) {
      this.viewBuildings = cloneDeep(this.viewSettingsQuery.facilitiesSignage.buildings);
      this.viewFloors = cloneDeep(this.viewSettingsQuery.facilitiesSignage.floors);
    }
    this.setModelFromStore();
  }

  changeHandler(params: string) : void {
    if (!this.model) this.setModelFromStore();

    if (this.modelSet.has(params)) {
      this.model.splice(this.model.indexOf(params), 1);
    } else {
      this.model.push(params);
    }
  }

  allFloorsHandler(): void {
    if (this.isAllFloorsChecked) {
      this.model = [];
    } else {
      this.model = cloneDeep(this.floors);
    }
  }

  cancel(): void {
    this.setModelFromStore();
    this.setIsAreaShown(false);
  }

  reset(): void {
    this.model = [];
    if (this.viewBuildings.includes(this.building)) {
      const index = this.viewFloors.findIndex(f => f.building === this.building);
      if (index !== -1) {
        this.model = cloneDeep(this.viewFloors[index].floors);
      }
    }
  }

  submit(): void {
    if (this.model.length === 0) {
      this.removeBuilding(this.building);
    } else {
      const params: FloorSettings = {
        building: this.building,
        floors: this.model
      };
      this.setFloorsByBuilding(params);
      this.$emit('area', this.building);
    }
    this.setIsAreaShown(false);
  }
}
