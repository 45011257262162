import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import UserNotificationSetting from '@/components/organisms/UserNotificationSetting.vue';
import { UiImIntegration } from '@/models/imIntegration';
import Loading from '@/components/atoms/Loading.vue';
import MailIcon from '@/assets/images/icon-mail-blue.png';
import SlackIcon from '@/assets/images/icon-slack.png';
import MsTeamsIcon from '@/assets/images/icon-ms-teams.png';
import HangoutsChatIcon from '@/assets/images/icon-hangouts-chat.png';

@Component({ components: { UserNotificationSetting, Loading } })
export default class UserNotificationSettings extends Vue {
  @Prop({ type: Array }) private services!: UiImIntegration[];
  
  @Prop({ type: Boolean }) private loading!: boolean;

  serviceIconTable = {
    slack: SlackIcon,
    email: MailIcon,
    teams: MsTeamsIcon,
    hangouts: HangoutsChatIcon,
  };
}
