import Vue from 'vue';
import Router, { Route, NavigationGuardNext } from 'vue-router';
import _ from 'lodash';
import store from '@/store';
import { isLoggedIn, resetAuth } from '@/helpers/auth';

Vue.use(Router);

function checkToken(from: Route, to: Route, next: NavigationGuardNext): void {
  if (isLoggedIn()) {
    next();
  } else {
    resetAuth();
  }
}

function checkTabletViewPermission(from: Route, to: Route, next: NavigationGuardNext): void {
  const roles = store.state.user.accountRoles;
  const currentTenantId = store.state.tenant.current.id;
  const tabletViewRoles = ['operator', 'admin', 'public'];
  const hasTabletViewPermission = roles.some(
    (r) => r.tenant_id === currentTenantId && tabletViewRoles.includes(r.role),
  );
  if (hasTabletViewPermission) {
    next();
  } else {
    next(false);
  }
}

function checkTokenAndTabletViewPermission(from: Route, to: Route, next: NavigationGuardNext): void {
  if (!isLoggedIn()) {
    resetAuth();
  } else {
    checkTabletViewPermission(from, to, next);
  }
}

const BASIC_ROUTES = [
  {
    name: 'logout',
    path: '/logout',
    components: {
      default: (): Promise<any> => import('@/views/shared/Logout.vue'),
    },
  },
  {
    name: 'login',
    path: '/login',
    component: (): Promise<any> => import('@/views/user/Login.vue'),
  },
  {
    name: 'facilityDashboard',
    path: '/room',
    component: (): Promise<any> => import('@/views/facility/FacilityDashboard.vue'),
    beforeEnter: checkTokenAndTabletViewPermission,
  },
  {
    path: '/rooms',
    name: 'rooms',
    components: {
      default: (): Promise<any> => import('@/views/user/Dashboard.vue'),
    },
    props: {
      default: (route: Route): object => {
        const meta = _.pick(route.meta, ['mode']);
        return { ...route.params, ...route.query, ...meta };
      },
    },
    beforeEnter: checkToken,
    redirect: { name: 'facilitiesSignage' },
    children: [
      {
        name: 'signage',
        path: 'signage',
        redirect: { name: 'facilitiesSignage' },
      },
      {
        name: 'facilitiesSignage',
        path: 'signage/facilities',
        components: {
          view: (): Promise<any> => import('@/views/user/FacilitiesSignage.vue'),
        },
        meta: { mode: 'signage' },
        props: {
          view: (route: Route): object => ({ ...route.params, ...route.query }),
        },
      },
      {
        name: 'bookingFacility',
        path: 'booking',
        components: {
          view: (): Promise<any> => import('@/views/user/BookingFacility.vue'),
        },
        meta: { mode: 'fullscreen' },
        props: {
          view: (route: Route): object => ({ ...route.params, ...route.query }),
        },
      },
      {
        name: 'facilityDashboardLinks',
        path: 'list',
        components: {
          view: (): Promise<any> => import('@/views/user/FacilityDashboardLinks.vue'),
        },
        props: {
          view: (route: Route): object => ({ ...route.params, ...route.query }),
        },
        beforeEnter: checkTabletViewPermission
      },
      // TODO: Remove old routing for migration
      {
        name: 'dashboard',
        path: 'dashboard',
        redirect: { name: 'facilitiesSignage' },
      },
      {
        name: 'dashboardSelector',
        path: 'dashboard/selector',
        components: {
          view: (): Promise<any> => import('@/views/user/FacilitiesSignage.vue'),
        },
      },
    ],
  },
];

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    ...BASIC_ROUTES,

    // TODO: Set 404 page
    ...[
      {
        path: '*',
        redirect: { name: 'facilitiesSignage' },
      },
    ],
  ],
});

export default router;

/**
 * redirectToLogin Redirects to login page.
 *
 * @param {boolean} withUrlParam - The flag reporesents
 *  if login path has current URL as URL search query
 */
export function redirectToLogin(withUrlParam = true): void {
  if (withUrlParam) {
    router.push({ name: 'login', query: { url: encodeURIComponent(window.location.href) } });
  } else {
    router.push({ name: 'login' });
  }
}
