import Vue from 'vue';
import Component from 'vue-class-component';
import { ToggleButton } from 'vue-js-toggle-button';
import { Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { UiImIntegration } from '@/models/imIntegration';
import Dropdown, { DropdownItem } from '@/components/molecules/Dropdown.vue';
import SuccessIcon from '@/assets/images/success.svg';
import { UserDetails } from '@/store/types/modules';

@Component({ components: { ToggleButton, Dropdown, SuccessIcon } })
export default class UserNotificationSetting extends Vue {
  @Prop({ type: Object }) private service!: UiImIntegration;

  @State('user') user!: UserDetails;

  toggle_button_style_props = {
    color: {
      checked: '#bde8ce',
      unchecked: '#bde8ce',
    },
    switchColor: {
      checked: '#45cf7c',
      unchecked: 'white',
    },
    width: 36,
    height: 12,
    margin: -4,
  };

  get allowedAddresses(): DropdownItem[] {
    const allowed = [{ itemKey: 'unspecified', name: this.$gettext('All') }];
    if (this.user.accountEmails) {
      this.user.accountEmails.forEach(email => allowed.push({ itemKey: email, name: email }));
    }
    return allowed;
  }

  get providerIdTitle(): string {
    if (this.service.im_provider_id === 'hangouts') {
      return 'hangouts chat';
    }
    if (this.service.im_provider_id === 'email') {
      return this.$gettext('Email');
    }
    return this.service.im_provider_id;
  }
}
