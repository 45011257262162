/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Identity
 */
export interface Identity {
    /**
     * 
     * @type {string}
     * @memberof Identity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Identity
     */
    provider_id: IdentityProviderIdEnum;
    /**
     * 
     * @type {string}
     * @memberof Identity
     */
    subject: string;
    /**
     * 
     * @type {Date}
     * @memberof Identity
     */
    updated?: Date;
}

export function IdentityFromJSON(json: any): Identity {
    return IdentityFromJSONTyped(json, false);
}

export function IdentityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Identity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'provider_id': json['provider_id'],
        'subject': json['subject'],
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
    };
}

export function IdentityToJSON(value?: Identity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'provider_id': value.provider_id,
        'subject': value.subject,
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
    };
}

/**
* @export
* @enum {string}
*/
export enum IdentityProviderIdEnum {
    Google = 'google',
    Microsoft = 'microsoft'
}


