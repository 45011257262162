/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IMTenantPreference
 */
export interface IMTenantPreference {
    /**
     * 
     * @type {string}
     * @memberof IMTenantPreference
     */
    im_tenant_id: string;
    /**
     * 
     * @type {string}
     * @memberof IMTenantPreference
     */
    im_provider_id: IMTenantPreferenceImProviderIdEnum;
    /**
     * 
     * @type {Date}
     * @memberof IMTenantPreference
     */
    updated?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof IMTenantPreference
     */
    active: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof IMTenantPreference
     */
    allowed_domains: Array<string>;
}

export function IMTenantPreferenceFromJSON(json: any): IMTenantPreference {
    return IMTenantPreferenceFromJSONTyped(json, false);
}

export function IMTenantPreferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): IMTenantPreference {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'im_tenant_id': json['im_tenant_id'],
        'im_provider_id': json['im_provider_id'],
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'active': json['active'],
        'allowed_domains': json['allowed_domains'],
    };
}

export function IMTenantPreferenceToJSON(value?: IMTenantPreference | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'im_tenant_id': value.im_tenant_id,
        'im_provider_id': value.im_provider_id,
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'active': value.active,
        'allowed_domains': value.allowed_domains,
    };
}

/**
* @export
* @enum {string}
*/
export enum IMTenantPreferenceImProviderIdEnum {
    Email = 'email',
    Slack = 'slack',
    Teams = 'teams',
    Hangouts = 'hangouts'
}


