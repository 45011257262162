/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FacilityFeatures,
    FacilityFeaturesFromJSON,
    FacilityFeaturesFromJSONTyped,
    FacilityFeaturesToJSON,
} from './';

/**
 * 
 * @export
 * @interface Facility
 */
export interface Facility {
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    facility_id: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    facility_name: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    calendar_integration_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Facility
     */
    calendar_id?: string;
    /**
     * 
     * @type {Date}
     * @memberof Facility
     */
    updated?: Date;
    /**
     * 
     * @type {FacilityFeatures}
     * @memberof Facility
     */
    features?: FacilityFeatures;
}

export function FacilityFromJSON(json: any): Facility {
    return FacilityFromJSONTyped(json, false);
}

export function FacilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Facility {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facility_id': json['facility_id'],
        'facility_name': json['facility_name'],
        'calendar_integration_id': !exists(json, 'calendar_integration_id') ? undefined : json['calendar_integration_id'],
        'calendar_id': !exists(json, 'calendar_id') ? undefined : json['calendar_id'],
        'updated': !exists(json, 'updated') ? undefined : (new Date(json['updated'])),
        'features': !exists(json, 'features') ? undefined : FacilityFeaturesFromJSON(json['features']),
    };
}

export function FacilityToJSON(value?: Facility | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'facility_id': value.facility_id,
        'facility_name': value.facility_name,
        'calendar_integration_id': value.calendar_integration_id,
        'calendar_id': value.calendar_id,
        'updated': value.updated === undefined ? undefined : (value.updated.toISOString()),
        'features': FacilityFeaturesToJSON(value.features),
    };
}


