var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Card",class:{ 'Card--transparent': !_vm.service.active }},[_c('toggle-button',_vm._b({attrs:{"value":_vm.service.active,"sync":true},on:{"change":function($event){return _vm.$emit('update:active', $event.value)}}},'toggle-button',_vm.toggle_button_style_props,false)),_c('div',{staticClass:"Card__header"},[_vm._t("icon"),_c('div',{staticClass:"Card__title"},[_vm._v(_vm._s(_vm.providerIdTitle))])],2),_c('div',{staticClass:"Card__label"},[_vm._v("ID: "+_vm._s(_vm.service.im_integration_id))]),_c('div',{staticClass:"Card__content"},[_c('translate',{staticClass:"Form__description"},[_vm._v(" Recipient(s) ")]),_c('div',{staticClass:"Form__dropdown-wrapper"},[_c('div',{staticClass:"Form__dropdown-choice"},[_c('p',{staticClass:"Form__dropdown-choice__text"},[_vm._v(" "+_vm._s(_vm.service.im_user_id === 'unspecified' ? _vm.$gettext('All') : _vm.service.im_user_id)+" ")])]),_c('dropdown',{attrs:{"id":("js-" + (_vm.service.im_integration_id) + "-dropdown"),"enable-tail":false,"position":"down","align":"start","items":_vm.allowedAddresses,"disabled":!_vm.service.active,"short":""},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('div',{staticClass:"Form__dropdown-arrow"})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var closeDropdown = ref.closeDropdown;
return [_c('div',[_c('a',{on:{"click":function($event){$event.preventDefault();return (function () {
                  _vm.$emit('update:im_user_id', item.itemKey);
                  closeDropdown();
                })($event)},"touchstart":function($event){$event.preventDefault();return (function () {
                  _vm.$emit('update:im_user_id', item.itemKey);
                  closeDropdown();
                })($event)}}},[_c('span',{staticClass:"Form__dropdown-item",class:{
                  'Form__dropdown-item--selected': item.itemKey.includes(_vm.service.im_user_id),
                },attrs:{"title":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.itemKey.includes(_vm.service.im_user_id))?_c('success-icon',{staticClass:"Form__success-icon"}):_vm._e()],1)])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }