import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

export const consoleLogger: { [key: string]: (...args: any) => void } = {
  info(message: string, data?: any): void {
    const args = data ? [message, data] : [message];
    console.info(...args);
  },
  debug(message: string, data?: any): void {
    const args = data ? [message, data] : [message];
    console.debug(...args);
  },
  warn(message: string, data?: any): void {
    const args = data ? [message, data] : [message];
    console.warn(...args);
  },
  error(message: string, data?: any): void {
    const args = data ? [message, data] : [message];
    console.error(...args);
  },
};

export type LoggerLevel = 'info' | 'debug' | 'warning' | 'error';
export type LoggerMethodNames = 'info' | 'debug' | 'warn' | 'error';

const consoleMethodTable: { [key in LoggerLevel]: LoggerMethodNames } = {
  info: 'info',
  debug: 'debug',
  error: 'error',
  warning: 'warn',
};

// @see https://github.com/getsentry/sentry-javascript/issues/1176#issuecomment-353032034
const isErrorOrErrorEvent = (wat: any): boolean =>
  Object.prototype.toString.call(wat) === '[object Error]' ||
  Object.prototype.toString.call(wat) === '[object ErrorEvent]';

const sentryMessage = (message: string, data?: any, level?: Sentry.Severity): void => {
  const consoleMethodName = consoleMethodTable[level as LoggerLevel];
  const consoleLoggerFunc = consoleLogger[consoleMethodName];
  consoleLoggerFunc(message, data);

  Sentry.addBreadcrumb({ message, data, level, category: 'tenri-logger' });

  if (level === Sentry.Severity.Warning) {
    Sentry.captureMessage(message, level);
    return;
  }

  if (level !== Sentry.Severity.Error) {
    return;
  }

  if (isErrorOrErrorEvent(data)) {
    Sentry.captureException(data);
    return;
  }

  Sentry.captureMessage(message, level);
};

export const sentryLogger: { [key: string]: (...args: any) => void } = {
  info(message: string, data?: any): void {
    sentryMessage(message, data, Sentry.Severity.Info);
  },
  debug(message: string, data?: any): void {
    sentryMessage(message, data, Sentry.Severity.Debug);
  },
  warn(message: string, data?: any): void {
    sentryMessage(message, data, Sentry.Severity.Warning);
  },
  error(message: string, data?: any): void {
    sentryMessage(message, data, Sentry.Severity.Error);
  },
};

export const isSentryEnabled = (): boolean => process.env.NODE_ENV === 'production';

const version = process.env.VUE_APP_VERSION || '';

if (isSentryEnabled()) {
  const sentryDsn = process.env.VUE_APP_SENTRY_DSN;
  console.info('Sentry is enabled', { env: process.env.NODE_ENV });

  Sentry.init({
    dsn: sentryDsn,
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
    release: version,
    beforeBreadcrumb(breadcrumb: Sentry.Breadcrumb): Sentry.Breadcrumb | null {
      return breadcrumb.category === 'console' ? null : breadcrumb;
    },
  });
  Sentry.configureScope((scope: Sentry.Scope): void => {
    scope.setTag('release', version);
  });
}

export default isSentryEnabled() ? sentryLogger : consoleLogger;
