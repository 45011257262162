/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IMUserPreference,
    IMUserPreferenceFromJSON,
    IMUserPreferenceToJSON,
    User,
    UserFromJSON,
    UserToJSON,
    UserPreference,
    UserPreferenceFromJSON,
    UserPreferenceToJSON,
} from '../models';

export interface GetIMUserPreferenceRequest {
    tenantId: string;
    imIntegrationId: string;
}

export interface PostIMUserPreferenceRequest {
    tenantId: string;
    imIntegrationId: string;
    iMUserPreference: IMUserPreference;
}

export interface PostUsersMePreferenceRequest {
    userPreference: UserPreference;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Get IM integration preference of a user under a specific tenant
     * Get IM integration preference of a user under a specific tenant
     */
    async getIMUserPreferenceRaw(requestParameters: GetIMUserPreferenceRequest): Promise<runtime.ApiResponse<IMUserPreference>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getIMUserPreference.');
        }

        if (requestParameters.imIntegrationId === null || requestParameters.imIntegrationId === undefined) {
            throw new runtime.RequiredError('imIntegrationId','Required parameter requestParameters.imIntegrationId was null or undefined when calling getIMUserPreference.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenant_id}/im_integrations/{im_integration_id}/user_preference`.replace(`{${"tenant_id"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"im_integration_id"}}`, encodeURIComponent(String(requestParameters.imIntegrationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IMUserPreferenceFromJSON(jsonValue));
    }

    /**
     * Get IM integration preference of a user under a specific tenant
     * Get IM integration preference of a user under a specific tenant
     */
    async getIMUserPreference(requestParameters: GetIMUserPreferenceRequest): Promise<IMUserPreference> {
        const response = await this.getIMUserPreferenceRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get current user\'s information
     * Get current user\'s information
     */
    async getUsersMeRaw(): Promise<runtime.ApiResponse<User>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Get current user\'s information
     * Get current user\'s information
     */
    async getUsersMe(): Promise<User> {
        const response = await this.getUsersMeRaw();
        return await response.value();
    }

    /**
     * Get current user\'s general preference
     * Get current user\'s general preference
     */
    async getUsersMePreferenceRaw(): Promise<runtime.ApiResponse<UserPreference>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/preference`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPreferenceFromJSON(jsonValue));
    }

    /**
     * Get current user\'s general preference
     * Get current user\'s general preference
     */
    async getUsersMePreference(): Promise<UserPreference> {
        const response = await this.getUsersMePreferenceRaw();
        return await response.value();
    }

    /**
     * Save IM integration preference of a user under a specific tenant
     * Save IM integration preference of a user under a specific tenant
     */
    async postIMUserPreferenceRaw(requestParameters: PostIMUserPreferenceRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling postIMUserPreference.');
        }

        if (requestParameters.imIntegrationId === null || requestParameters.imIntegrationId === undefined) {
            throw new runtime.RequiredError('imIntegrationId','Required parameter requestParameters.imIntegrationId was null or undefined when calling postIMUserPreference.');
        }

        if (requestParameters.iMUserPreference === null || requestParameters.iMUserPreference === undefined) {
            throw new runtime.RequiredError('iMUserPreference','Required parameter requestParameters.iMUserPreference was null or undefined when calling postIMUserPreference.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenant_id}/im_integrations/{im_integration_id}/user_preference`.replace(`{${"tenant_id"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"im_integration_id"}}`, encodeURIComponent(String(requestParameters.imIntegrationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IMUserPreferenceToJSON(requestParameters.iMUserPreference),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Save IM integration preference of a user under a specific tenant
     * Save IM integration preference of a user under a specific tenant
     */
    async postIMUserPreference(requestParameters: PostIMUserPreferenceRequest): Promise<void> {
        await this.postIMUserPreferenceRaw(requestParameters);
    }

    /**
     * Save current user\'s general preference
     * Save current user\'s general preference
     */
    async postUsersMePreferenceRaw(requestParameters: PostUsersMePreferenceRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userPreference === null || requestParameters.userPreference === undefined) {
            throw new runtime.RequiredError('userPreference','Required parameter requestParameters.userPreference was null or undefined when calling postUsersMePreference.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/me/preference`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserPreferenceToJSON(requestParameters.userPreference),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Save current user\'s general preference
     * Save current user\'s general preference
     */
    async postUsersMePreference(requestParameters: PostUsersMePreferenceRequest): Promise<void> {
        await this.postUsersMePreferenceRaw(requestParameters);
    }

}
