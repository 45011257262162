import firebase from 'firebase/app';
import logger from '@/logger';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
};

export default {
  install(Vue: any): void {
    firebase.initializeApp(firebaseConfig);
    try {
      Vue.prototype.$firebaseMessaging = firebase.messaging();
    } catch (err) {
      if (err.message.match(/.+\(messaging\/unsupported-browser\)/g)) {
        logger.info('FCM is not supported in this browser, so push notifications are not enabled.');
      } else {
        logger.error(err);
      }
    }
  },
};
