import Vue, { VNode } from 'vue';
import { sync } from 'vuex-router-sync';
import VueAnalytics from 'vue-analytics';
import Vue2TouchEvents from 'vue2-touch-events';
import { Component } from 'vue-property-decorator';

import Hotjar from 'vue-hotjar';
import VueGtm from 'vue-gtm';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/logger';
import '@/language';
import '@/registerServiceWorker';
import firebasePlugin from '@/plugins/firebase-plugin';

const main = (): void => {
  const gaId = process.env.VUE_APP_GOOGLE_ANALYTICS_ID;
  Vue.use(VueAnalytics, {
    id: gaId,
    router
  });

  const gtmId = process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID;
  Vue.use(VueGtm, {
    id: gtmId,
    vueRouter: router,
  });

  Vue.use(Vue2TouchEvents);

  Vue.use(Hotjar, {
    id: process.env.VUE_APP_HOTJAR_SITE_ID, // Hotjar Site ID
    isProduction: process.env.NODE_ENV === 'production'
  });

  if (process.env.NODE_ENV === 'production') {
    Vue.use(firebasePlugin);
  }

  sync(store, router);

  new Vue({
    router,
    store,
    render: (h): VNode => h(App),
  }).$mount('#app');

  Component.registerHooks(['validations']);
};

main();
