/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    IMTenantPreference,
    IMTenantPreferenceFromJSON,
    IMTenantPreferenceToJSON,
    Tenant,
    TenantFromJSON,
    TenantToJSON,
} from '../models';

export interface GetIMTenantPreferenceRequest {
    tenantId: string;
    imIntegrationId: string;
}

/**
 * 
 */
export class TenantApi extends runtime.BaseAPI {

    /**
     * Get IM integration preference of a tenant
     * Get IM integration preference of a tenant
     */
    async getIMTenantPreferenceRaw(requestParameters: GetIMTenantPreferenceRequest): Promise<runtime.ApiResponse<IMTenantPreference>> {
        if (requestParameters.tenantId === null || requestParameters.tenantId === undefined) {
            throw new runtime.RequiredError('tenantId','Required parameter requestParameters.tenantId was null or undefined when calling getIMTenantPreference.');
        }

        if (requestParameters.imIntegrationId === null || requestParameters.imIntegrationId === undefined) {
            throw new runtime.RequiredError('imIntegrationId','Required parameter requestParameters.imIntegrationId was null or undefined when calling getIMTenantPreference.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants/{tenant_id}/im_integrations/{im_integration_id}/tenant_preference`.replace(`{${"tenant_id"}}`, encodeURIComponent(String(requestParameters.tenantId))).replace(`{${"im_integration_id"}}`, encodeURIComponent(String(requestParameters.imIntegrationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => IMTenantPreferenceFromJSON(jsonValue));
    }

    /**
     * Get IM integration preference of a tenant
     * Get IM integration preference of a tenant
     */
    async getIMTenantPreference(requestParameters: GetIMTenantPreferenceRequest): Promise<IMTenantPreference> {
        const response = await this.getIMTenantPreferenceRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get accessible tenant list
     * Get Tenants
     */
    async getTenantsRaw(): Promise<runtime.ApiResponse<Array<Tenant>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tenants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TenantFromJSON));
    }

    /**
     * Get accessible tenant list
     * Get Tenants
     */
    async getTenants(): Promise<Array<Tenant>> {
        const response = await this.getTenantsRaw();
        return await response.value();
    }

}
