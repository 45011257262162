import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { cloneDeep } from 'lodash';
import { SettingsQuery, FloorSettings, SignageUrlQuery } from '@/store/types/modules';
import VueCheckbox from '@/components/atoms/Checkbox.vue';
import BuildingAreas from '@/components/molecules/BuildingAreas.vue';

const signageSettings = namespace('signageSettings');

const view = namespace('view');

@Component({
  components: { VueCheckbox, BuildingAreas },
})
export default class BuildingsCheckboxGroup extends Vue {
  @Prop({ type: Array, required: true }) private buildings!: string[];

  @Prop({ type: Object, required: true }) private defaultView!: SignageUrlQuery;

  @view.State('settingsQuery') viewSettingsQuery!: SettingsQuery;

  @signageSettings.State('selectedBuildings') selectedBuildings!: string[];

  @signageSettings.State('selectedFloors') selectedFloors!: FloorSettings[];

  @signageSettings.Mutation('setBuildings') signageSettingsSetBuildings!: (buildings: string[]) => void;

  @signageSettings.Mutation('setFloors') signageSettingsSetFloors!: (params: FloorSettings[]) => void;

  @signageSettings.Mutation('setIsAreaShown') signageSettingsSetIsAreaShown!: (params: boolean) => void;

  model: string[] = [];

  currentBuilding = '';

  get selectedBuildingsSet(): Set<string> {
    return new Set(this.selectedBuildings);
  }

  get isAllBuildingsChecked(): boolean {
    return this.buildings && this.selectedBuildings && this.buildings.length === this.selectedBuildings.length;
  }

  async created(): Promise<void> {
    await this.init();
  }

  @Watch('selectedBuildings')
  async init(): Promise<void> {
    this.model = cloneDeep(this.selectedBuildings);
  }

  async getFloorsByBuilding(building: string): Promise<string[]> {
    const index = this.defaultView.floors.findIndex(f => f.building === building);
    if (index !== -1) {
      return this.defaultView.floors[index].floors;
    }
    return [];
  }

  async changeHandler(building: string): Promise<void> {
    if (this.selectedBuildingsSet.has(building)) {
      this.model.splice(this.model.indexOf(building), 1);
      await this.uncheckAllFloors(building);
    } else {
      this.model.push(building);
      await this.checkAllFloors(building);
    }
    this.signageSettingsSetBuildings(this.model);
  }

  async checkAllFloors(building: string): Promise<void> {
    const floors = await this.getFloorsByBuilding(building);
    const params = this.selectedFloors;
    const hasBuilding = this.selectedFloors.some(f => f.building === building);

    if (!hasBuilding) {
      params.push({
        building,
        floors
      });
      this.signageSettingsSetFloors(params);
    }
  }

  async uncheckAllFloors(building: string): Promise<void> {
    const params = this.selectedFloors;
    const index = this.selectedFloors.findIndex(f => f.building === building);

    if (index !== -1) {
      params.splice(index, 1);
      this.signageSettingsSetFloors(params);
    }
  }

  async allBuildingsHandler(): Promise<void> {
    if (this.isAllBuildingsChecked) {
      this.model = [];
      this.signageSettingsSetFloors([]);
    } else {
      this.model = cloneDeep(this.buildings);
      await this.checkAllBuildingsFloors();
    }
    this.signageSettingsSetBuildings(this.model);
  }

  async checkAllBuildingsFloors(): Promise<void> {
    await this.model.forEach(building => {
      const hasBuilding = this.selectedFloors.some(f => f.building === building);
      if (!hasBuilding) {
        this.checkAllFloors(building);
      }
    });
  }

  areaHandler(params: string) : void {
    if (!this.selectedBuildingsSet.has(params)) {
      this.model.push(params);
      this.signageSettingsSetBuildings(this.model);
    }
  }

  selectAreaHandler(params: string): void {
    this.currentBuilding = params;
    this.signageSettingsSetIsAreaShown(true);
  }

  reset(): void {
    if (this.viewSettingsQuery.facilitiesSignage) {
      this.model = cloneDeep(this.viewSettingsQuery.facilitiesSignage.buildings);
    } else {
      this.model = [];
    }
    this.signageSettingsSetBuildings(this.model);
  }
}
