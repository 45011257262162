/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Event,
    EventFromJSON,
    EventFromJSONTyped,
    EventToJSON,
} from './';

/**
 * 
 * @export
 * @interface FacilityStatus
 */
export interface FacilityStatus {
    /**
     * 
     * @type {string}
     * @memberof FacilityStatus
     */
    facility_id: string;
    /**
     * 
     * @type {boolean}
     * @memberof FacilityStatus
     */
    is_device_dead: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FacilityStatus
     */
    is_facility_used: boolean;
    /**
     * 
     * @type {string}
     * @memberof FacilityStatus
     */
    door_status: FacilityStatusDoorStatusEnum;
    /**
     * 
     * @type {Event}
     * @memberof FacilityStatus
     */
    event?: Event;
    /**
     * 
     * @type {Event}
     * @memberof FacilityStatus
     */
    next_event?: Event;
}

export function FacilityStatusFromJSON(json: any): FacilityStatus {
    return FacilityStatusFromJSONTyped(json, false);
}

export function FacilityStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): FacilityStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'facility_id': json['facility_id'],
        'is_device_dead': json['is_device_dead'],
        'is_facility_used': json['is_facility_used'],
        'door_status': json['door_status'],
        'event': !exists(json, 'event') ? undefined : EventFromJSON(json['event']),
        'next_event': !exists(json, 'next_event') ? undefined : EventFromJSON(json['next_event']),
    };
}

export function FacilityStatusToJSON(value?: FacilityStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'facility_id': value.facility_id,
        'is_device_dead': value.is_device_dead,
        'is_facility_used': value.is_facility_used,
        'door_status': value.door_status,
        'event': EventToJSON(value.event),
        'next_event': EventToJSON(value.next_event),
    };
}

/**
* @export
* @enum {string}
*/
export enum FacilityStatusDoorStatusEnum {
    Unknown = 'unknown',
    Open = 'open',
    Closed = 'closed'
}


