import process from 'process';

/**
 * API_REPRIODICAL_LISTING_DURATION is time to periodically fetch
 *  a list of something via Tenri API.
 *  The unit is millisecond.
 *
 * @constant
 */
export const API_PERIODICAL_LISTING_DURATION: number = parseInt(
  process.env.LISTING_DURATION || '60000',
  10,
);

/**
 * API_CACHE_MINUTES is cache time of API response. Tenri API caches
 *  data of G suite or Office 365 because they return the data slowly.
 *  Tenri UI should take care the cache timing to show the latest data.
 *  The unit is minute.
 *
 * @constant
 */
export const API_CACHE_MINUTES: number = parseInt(process.env.API_CACHE_MINUTES || '5', 10);

/**
 * API_BOOKING_OFFSET_TIME is offset time to book facility in Tenri API.
 *  Tenri API add offset time because second unit of booking time is ignored.
 *
 *  For example, when booking button is clicked at 12:00:58,
 *  Tenri API will receive it at 12:01:03 because of network delay and so on...
 *  Therefore, Tenri API ignores second unit then adds offset time.
 *
 *  To calculate the end time of event, the offset time also is handled on Tenri UI.
 *  The unit is millisecond.
 *
 * @constant
 */
export const API_BOOKING_OFFSET_TIME: number = parseInt(
  process.env.API_BOOKING_OFFSET_TIME || '60000',
  10,
);
