import { Module } from 'vuex';
import { RootState } from '@/store/types/rootState';
import { AuthToken } from '@/store/types/modules';

const authTokenModule: Module<AuthToken, RootState> = {
  state: { token: '' },
  mutations: {
    setAuthToken(state: AuthToken, t: AuthToken): void {
      state.token = t.token;
      state.expiresAt = t.expiresAt;
      state.issuer = t.issuer;
      state.subject = t.subject;
    },
  },
};

export default authTokenModule;
