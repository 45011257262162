import { Module } from 'vuex';
import { RootState } from '@/store/types/rootState';
import { UserDetails } from '@/store/types/modules';

const userModule: Module<UserDetails, RootState> = {
  namespaced: true,
  state: {
    accountId: '',
    accountActive: false,
    accountLanguage: 'en',
    accountTimezone: 'Asia/Tokyo',
    accountRoles: []
  },
  mutations: {
    setUserDetails(state: UserDetails, u: UserDetails): void {
      state.accountActive = u.accountActive;
      state.accountId = u.accountId;
      state.accountEmails = u.accountEmails ? [...u.accountEmails] : [];
      state.accountLanguage = u.accountLanguage;
      state.accountTimezone = u.accountTimezone;
      state.accountRoles = u.accountRoles;
    },
  },
};

export default userModule;
