import { Module } from 'vuex';
import { RootState } from '@/store/types/rootState';

export type MessageStatus = 'success' | 'warning' | 'info' | 'error' | 'wait';
export type MessageTheme = 'slim' | 'squire';

export interface Message {
  text: string;
  status: MessageStatus;
  theme?: MessageTheme;
  isFlashed?: boolean;
}

export interface FlashMessage {
  messages: Message[];
}

const flashMessageModule: Module<FlashMessage, RootState> = {
  state: { messages: [] },
  mutations: {
    addMessage(state: FlashMessage, v: Message): number {
      return state.messages.push(v) - 1;
    },
    removeMessage(state: FlashMessage, text: string): void {
      state.messages = state.messages.filter(msg => msg.text !== text);
    },
    resetMessages(state: FlashMessage): void {
      state.messages = [];
    },
  },
};

export default flashMessageModule;
