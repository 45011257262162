import firebase from 'firebase/app';
import 'firebase/database';
import { Module } from 'vuex';
import { RootState } from '@/store/types/rootState';
import { Firebase } from '@/store/types/modules';

const firebaseModule: Module<Firebase, RootState> = {
  namespaced: true,
  state: {
  },
  mutations: {
    setToken(state: Firebase, token: string):void {
      state.token = token;
    },
    removeToken(state: Firebase):void {
      state.token = undefined;
    }
  },
  actions: {
    setToken({ commit }, token: string):void {
      const database = firebase.database();
      database.ref("device_ids").once('value').then(snapshots => {
        let found = false;
        snapshots.forEach(snapshot => {
          if (snapshot.val() === token) {
            found = true;
          }
        });
        if (!found) {
          database.ref("device_ids").push(token);
        }
      });
      commit('setToken', token);
    },
    removeToken({ commit, state }):void {
      if (state.token) {
        const database = firebase.database();
        database.ref("device_ids").equalTo(state.token).on('child_added', 
          snapshot => {
            snapshot.ref.remove();
          });
        commit('removeToken');
      }
    }
  }
};

export default firebaseModule;
