import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Dropdown, { DropdownItem } from '@/components/molecules/Dropdown.vue';
import SuccessIcon from '@/assets/images/success.svg';

@Component({
  components: { Dropdown, SuccessIcon },
})
export default class UserSettingBasic extends Vue {

  @Prop({ type: String, required: true }) private title!: string;

  @Prop({ type: String, required: true }) private translatedTitle!: string;

  @Prop({ type: String, required: true }) private description!: string;

  @Prop({ type: String, required: true }) value!: string;

  @Prop({ type: Array, required: true }) dropdownItems!: DropdownItem[];

  get selected(): string {
    return this.value;
  }

  get titleLowerCase(): string {
    return this.title.toLocaleLowerCase();
  }

  isSelected(item: DropdownItem): boolean {
    return item.itemKey.indexOf(this.selected) > -1;
  }
}
