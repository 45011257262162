import { render, staticRenderFns } from "./UserNotificationSettings.vue?vue&type=template&id=484d64f0&scoped=true&"
import script from "./UserNotificationSettings.vue.ts?vue&type=script&lang=ts&"
export * from "./UserNotificationSettings.vue.ts?vue&type=script&lang=ts&"
import style0 from "./UserNotificationSettings.vue?vue&type=style&index=0&id=484d64f0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "484d64f0",
  null
  
)

export default component.exports