import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class Radio extends Vue {
  @Prop({ type: String, required: true }) private inputId!: string;

  @Prop({ type: String, required: true }) private inputValue!: string;

  @Prop({ type: String, required: true }) private label!: string;

  @Prop({ type: String, required: true }) private name!: string;

  @Prop({ type: String, required: true }) private model!: string;

  get isChecked(): boolean {
    return this.model === this.inputValue;
  }
}
