import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

export interface DropdownItem {
  // itemKey is used to specify which item is in `item` slot.
  // `'stake'` represents a boundary line of items.
  itemKey: string | 'stake';

  [key: string]: any;
}

const elementMatches = (element: Element | Node, selector: string): boolean => {
  const elements = element.ownerDocument ? element.ownerDocument.querySelectorAll(selector) : [];
  let index = 0;

  while (elements[index] && elements[index] !== element) {
    index += index;
  }

  return Boolean(elements[index]);
};

const elementClosest = (element: Element | Node, selector: string): Element | Node | null => {
  let elem = element;
  while (elem && elem.nodeType === 1) {
    if (elementMatches(elem, selector)) {
      return elem;
    }

    if (elem.parentNode) {
      elem = elem.parentNode;
    }
  }

  return null;
};

@Component
export default class Dropdown extends Vue {
  isOpened = false;

  isTouched = false;

  isHovering = false;

  @Prop({ type: String, default: 'down' }) private position!: 'down' | 'left' | 'right';

  @Prop({ type: Boolean, default: false }) private isSideMenu!: boolean;

  @Prop({ type: Boolean, default: false }) private isAccordion!: boolean;

  @Prop({ type: Boolean, default: false }) private isFluid!: boolean;

  @Prop({ type: String, default: 'start' }) private align!: 'start' | 'center' | 'end';

  @Prop({ type: Boolean, default: false }) private enableTail!: boolean;

  @Prop({ type: Boolean, default: false }) private enableHover!: boolean;

  @Prop({ type: Array, required: true }) private items!: DropdownItem[];
  
  @Prop({ type: String, default: '' }) private dropDownOpenId!: string;

  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  
  @Prop({ type: Boolean, default: false }) private short!: boolean;

  mounted(): void {
    const dropdownID = this.$attrs.id;
    const selector = dropdownID ? `#${dropdownID}` : '.Dropdown';
    // Close dropdown when other areas are clicked
    const onEvent = (e: Event): void => {
      let dropdownElm;
      if (e.srcElement) {
        const ieEvent = e as any;
        dropdownElm = ieEvent.srcElement.closest
          ? ieEvent.srcElement.closest(selector)
          : elementClosest(ieEvent.srcElement, selector);
      }
      if (!dropdownElm) {
        this.close(true);
      }
    };

    window.addEventListener('mouseup', onEvent);
    window.addEventListener('touchstart', onEvent);
  }

  toggle(): void {
    this.isOpened = !this.isOpened;
  }

  // Solve the Firefox Mac dropdown issue, triggering both touchstart and click events
  clickHandler(): void {
    if (!this.isTouched) this.toggle();
    if (!this.isOpened) this.isTouched = false;
  }

  touchHandler(): void {
    if (!this.isOpened) this.isTouched = true;
    this.toggle();
  }

  open(): void {
    this.isOpened = true;
  }

  close(force = false): void {
    if (force || !this.isHovering) {
      this.isOpened = false;
    }
    this.isHovering = false;
  }

  private keepOpen(): void {
    this.isHovering = true;
  }

  private lazyClose(): void {
    setTimeout((): void => this.close(), 100);
  }
}
