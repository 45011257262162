import { Module } from 'vuex';
import { RootState } from '@/store/types/rootState';

export interface UserPreferences {
  isShown: boolean
}

const userSettingsModule: Module<UserPreferences, RootState> = {
  namespaced: true,
  state: {
    isShown: false
  },
  mutations: {
    setIsShown(state: UserPreferences, isShown: boolean): void {
      state.isShown = isShown;
    }
  }
};

export default userSettingsModule;
