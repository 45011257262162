import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class Checkbox extends Vue {
  @Prop({ type: String, required: true }) private inputId!: string;

  @Prop({ type: String, required: true }) private label!: string;
  
  @Prop({ type: Boolean, required: false, default: false }) private checked?: boolean;
}
