import { Module } from 'vuex';
import { cloneDeep } from 'lodash';
import { RootState } from '@/store/types/rootState';
import { SignageSettings, FloorSettings } from '@/store/types/modules';

const signageSettingsModule: Module<SignageSettings, RootState> = {
  namespaced: true,
  state: {
    isShown: false,
    isAreaShown: false,
    selectedBuildings: [],
    selectedFloors: []
  },
  mutations: {
    setIsShown(state: SignageSettings, isShown: boolean): void {
      state.isShown = isShown;
    },
    setIsAreaShown(state: SignageSettings, isAreaShown: boolean): void {
      state.isAreaShown = isAreaShown;
    },
    setBuildings(state: SignageSettings, buildings: string[]): void {
      const temp = cloneDeep(buildings);
      state.selectedBuildings = temp.sort();
    },
    removeBuilding(state: SignageSettings, building: string): void {
      state.selectedBuildings.splice(state.selectedBuildings.indexOf(building), 1);
      const index = state.selectedFloors.findIndex(f => f.building === building);
      if (index !== -1) {
        state.selectedFloors.splice(index, 1);
      }
    },
    setFloors(state: SignageSettings, floors: FloorSettings[]): void {
      const temp = cloneDeep(floors);
      state.selectedFloors = temp.sort((a: FloorSettings, b: FloorSettings) => (a.building > b.building) ? 1 : -1);
    },
    setFloorsByBuilding(state: SignageSettings, params: FloorSettings): void {
      const temp = cloneDeep(state.selectedFloors);
      const index = state.selectedFloors.findIndex(f => f.building === params.building);
      if (index !== -1) {
        temp[index].floors = params.floors;
      } else {
        temp.push(params);
      }
      state.selectedFloors = temp.sort((a: FloorSettings, b: FloorSettings) => (a.building > b.building) ? 1 : -1);
    },
  }
};

export default signageSettingsModule;
