/**
 * Tenant component helper
 *
 * @desc helps do CRUD in components for tenants
 */
import { Store } from 'vuex';

import logger from '@/logger';
import { TenantDetails , AuthToken , Idp } from '@/store/types/modules';
import { DefaultCurrentTenant } from '@/store/tenant';
import apiConfiguration from '@/helpers/config';
import { Tenant } from '@/api/models';
import { TenantApi } from '@/api/apis/TenantApi';
import { authMiddleware } from '@/helpers/authToken';
import { displayInfo, displayWarn } from '@/helpers/message';
import { FlashMessage } from '@/store/flashMessage';

interface TenantHelperDeps {
  $gettext: (msgid: string) => string;
  $store: Store<{
    authToken: AuthToken;
    flashMessage: FlashMessage;
    tenant: TenantDetails;
    idp: Idp;
  }>;
}

export const emptyTenantsError = 'Empty tenant list returned from the API';

export default class TenantHelper {
  vue: TenantHelperDeps;

  constructor(deps: TenantHelperDeps) {
    this.vue = deps;
  }

  currentTenant(): Tenant {
    const { tenant } = this.vue.$store.state;
    return tenant.current;
  }

  currentTenantId(): string {
    const currentTenant = this.currentTenant();
    return currentTenant.id;
  }

  currentTenantName(): string {
    const currentTenant = this.currentTenant();
    return currentTenant ? currentTenant.name : this.vue.$gettext('Unknown Tenant');
  }

  allowedTenants(): Tenant[] {
    const { tenant } = this.vue.$store.state;
    return tenant.allowedTenants;
  }

  /**
   * validTenantId checks if login user can access the given tenant ID.
   *
   * @param {string} tenantId - Tenant ID
   * @return {Promise<boolean>} Promise object involved that
   * the flag represented if login user can access the given tenant ID
   */
  async validTenantId(tenantId: string): Promise<boolean> {
    const tenantExists = await new TenantApi(apiConfiguration)
      .withMiddleware(authMiddleware)
      .getTenants()
      .then(tenants => tenants.some(t => t.id === tenantId));

    if (!tenantExists) {
      displayWarn(this.vue.$gettext('Tenant ID is wrong'));
      logger.warn('Tenant ID is wrong', { tenantId });
    }

    return tenantExists;
  }

  /**
   * getTenantDetails gets the detail of tenant information.
   *
   * @return {Promise<TenantDetails>} Promise object involved that
   * the flag represented if auth API requests is succeeded
   */
  async getTenantDetails(): Promise<TenantDetails> {
    const tenantApi = new TenantApi(apiConfiguration)
      .withMiddleware(authMiddleware);
    const tenants = await tenantApi.getTenants();

    if (tenants.length === 0) {
      throw new Error(emptyTenantsError);
    }

    const currentTenant = this.currentTenant();

    return {
      // only update the current tenant if it is the default one at the moment
      current: currentTenant.id === DefaultCurrentTenant.id ? tenants[0] : currentTenant,
      allowedTenants: tenants,
    };
  }

  /**
   * switchCurrentTenant switches current tenant.
   *
   * @return {void} void
   */
  switchCurrentTenant(tenant: Tenant): void {
    displayInfo(this.vue.$gettext('Switching tenant...'));
    logger.info('Switching tenant...', { tenantId: tenant.id });

    this.vue.$store.dispatch('setCurrentTenant', tenant);

    displayInfo(this.vue.$gettext('Tenant has been switched'));
    logger.info('Tenant has been switched', { tenantId: tenant.id });
  }
}
