/* tslint:disable */
/* eslint-disable */
/**
 * HENNGE Workstyle Agile Meeting API
 * API for HENNGE Workstyle Agile Meeting service.
 *
 * The version of the OpenAPI document: 1
 * Contact: workstyle@hennge.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FacilityStatus,
    FacilityStatusFromJSON,
    FacilityStatusFromJSONTyped,
    FacilityStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {Date}
     * @memberof InlineResponse200
     */
    checked_time: Date;
    /**
     * 
     * @type {Array<FacilityStatus>}
     * @memberof InlineResponse200
     */
    status: Array<FacilityStatus>;
}

export function InlineResponse200FromJSON(json: any): InlineResponse200 {
    return InlineResponse200FromJSONTyped(json, false);
}

export function InlineResponse200FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse200 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'checked_time': (new Date(json['checked_time'])),
        'status': ((json['status'] as Array<any>).map(FacilityStatusFromJSON)),
    };
}

export function InlineResponse200ToJSON(value?: InlineResponse200 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'checked_time': (value.checked_time.toISOString()),
        'status': ((value.status as Array<any>).map(FacilityStatusToJSON)),
    };
}


