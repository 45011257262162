import { Module } from 'vuex';
import { RootState } from '@/store/types/rootState';
import { Idp } from '@/store/types/modules';

const idpModule: Module<Idp, RootState> = {
  mutations: {
    setIdp(state: Idp, i: Idp): void {
      state.idpName = i.idpName;
      state.idToken = i.idToken;
      state.expiresAt = i.expiresAt;
      state.profileId = i.profileId;
      state.profileName = i.profileName;
      state.profileEmail = i.profileEmail;
      state.profilePictureUrl = i.profilePictureUrl;
    },
  },
};

export default idpModule;
